import { createAuthentication } from './auth';
import { getDatabase, setDatabase } from './database';
import { feathers, apiQueue, apiContext, baseRequestAfterAuth, setAccessToken } from './feathers';
import { store } from './store';
import { wait } from '../utils';

const authenticator = createAuthentication({
  feathers,
  onAuthenticated: async ({ userId }) => {
    const accessToken = authenticator.findAccessTokenByUserId(userId).peek();
    if (!accessToken) return;
    setAccessToken(accessToken);
    await wait(10);
    setDatabase(userId, () => {});
  },
  onNetAuthenticated: async ({ userId }) => {
    const accessToken = authenticator.findAccessTokenByUserId(userId).peek();
    if (!accessToken) return;
    setAccessToken(accessToken);
    await wait(10);
    await baseRequestAfterAuth(userId);
    if (!apiQueue.active.peek()) {
      apiQueue.start();
    }
  },
  onUnauthenticated: async () => {
    await getDatabase()?.clear();
    store.clear();
    // window.location.replace('/auth');
  },
});

apiContext.connected.onChange((next) => {
  authenticator.setConnected(next);
});

export { authenticator, feathers, store, baseRequestAfterAuth, getDatabase, setDatabase };
export * from './requests';
