/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */

import { storageKeys } from '../constants';

/* eslint-disable @typescript-eslint/no-explicit-any */
const empty = (...args: any[]) => {}; // eslint-disable-line @typescript-eslint/no-unused-vars

export const LEVELS = {
  none: 0,
  error: 1,
  warn: 13,
  info: 19,
  debug: 20,
  dev: 21,
};

export const checkDebug = () => {
  const store = localStorage.getItem(storageKeys.devMode);
  return store ? Number(store) : LEVELS.error;
};

const setDevClass = () => {
  if (window.$$dev.devMode > 20 && import.meta.env.DEV) {
    document.body.classList.add('dev');
  } else {
    document.body.classList.remove('dev');
  }
};

window.$$dev = window.$$dev || {};
window.$$dev.devMode = checkDebug();
setDevClass();

const dev = () => window.$$dev.devMode;

window.$$dev.setDevMode = (mode: 'none' | 'error' | 'warn' | 'info' | 'debug') => {
  window.$$dev.devMode = LEVELS[mode];
  localStorage.setItem(storageKeys.devMode, LEVELS[mode].toString());
  setDevClass();
  return window.$$dev.devMode;
};

export const createDebug = (levels = LEVELS) => ({
  error: dev() >= levels.error ? console.error : empty,
  warn: dev() >= levels.warn ? console.warn : empty,
  log: dev() >= levels.info ? console.log : empty,
  info: dev() >= levels.info ? console.info : empty,
  dir: dev() >= levels.info ? console.dir : empty,
  dirxml: dev() >= levels.info ? console.dirxml : empty,
  table: dev() >= levels.info ? console.table : empty,
  time: dev() >= levels.info ? console.time : empty,
  timeLog: dev() >= levels.info ? console.timeLog : empty,
  timeStamp: dev() >= levels.info ? console.timeStamp : empty,
  timeEnd: dev() >= levels.info ? console.timeEnd : empty,
  // profile: dev() >= levels.info ? console.profile : empty,
  // profileEnd: dev() >= levels.info ? console.profileEnd : empty,
  assert: dev() >= levels.info ? console.assert : empty,
  count: dev() >= levels.info ? console.count : empty,
  countReset: dev() >= levels.info ? console.countReset : empty,
  group: dev() >= levels.info ? console.group : empty,
  groupCollapsed: dev() >= levels.info ? console.groupCollapsed : empty,
  groupEnd: dev() >= levels.info ? console.groupEnd : empty,
  trace: dev() >= levels.info ? console.trace : empty,
  debug: dev() >= levels.debug ? console.debug : empty,
  clear: console.clear,
  dev: dev() >= levels.dev ? console.debug : empty,
  custom: (customLevels: typeof LEVELS) => createDebug(customLevels),
});

export const debug = createDebug(LEVELS);
createDebug.levels = LEVELS;
