import type { TableColumnProps } from './TableColumn';

import clsx from 'clsx';

import { useTableColumn } from './TableColumn';

export type TableRowProps = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
};

export function TableRow(props: TableRowProps) {
  return (
    <tr
      tabIndex={props.onClick ? 0 : undefined}
      className={clsx('transition', {
        'hover:bg-gray-20 active:bg-gray-30 focus-visible:bg-gray-20': props.onClick,
      })}
      onClick={props.onClick}
    >
      {props.children}
    </tr>
  );
}

export type TableRowColumnProps = {
  children: React.ReactNode;
} & TableColumnProps;

TableRow.Column = function Column(props: TableRowColumnProps) {
  const { children, ...tableColumnProps } = props;
  const { className } = useTableColumn(tableColumnProps);

  return (
    <td
      className={clsx(className, {
        'font-medium text-gray-120': props.first,
        'text-gray-110': !props.first,
      })}
    >
      {children}
    </td>
  );
};
