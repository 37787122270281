export function toCamelCase(str: string): string {
  return str
    .replace(/[^a-zA-Z0-9\s_-]/g, '')
    .split(/\s+|_+|-+/) // Split by spaces, underscores, or hyphens
    .map((word, index) => {
      if (index === 0) {
        // First word: all lowercase
        return word.toLowerCase();
      }
      // Subsequent words: capitalize the first letter
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
}
