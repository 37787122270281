import type { UseBaseFieldProps } from './useBaseField';
import type { Observable } from '@legendapp/state';

import { memo, useEffect, useMemo, useRef } from 'react';

import { useObservable, useObserve } from '@legendapp/state/react';
import clsx from 'clsx';

import styles from './CheckboxField.module.css';
import { useBaseField } from './useBaseField';
import { createUniqueId } from '../../utils';

export type CheckboxFieldProps<T = boolean> = Omit<UseBaseFieldProps, 'fieldId'> & {
  id?: string;
  label?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: T;
  value$?: Observable<T>;
  checked?: boolean;
  required?: boolean;
};

export function CheckboxField(props: CheckboxFieldProps) {
  const ref = useRef<HTMLInputElement>(null);
  const id = useMemo(() => {
    return props.id || createUniqueId();
  }, [props.id]);
  const { fieldId, error, description, size, disabled, readonly } = useBaseField({
    ...props,
    fieldId: id,
  });

  const { value$, required } = props;

  const fieldStyle = useMemo(() => {
    return clsx(styles.checkboxField, 'relative flex items-start', styles[size], {
      [styles.disabled]: disabled,
      [styles.readonly]: readonly,
      [styles.hasError]: error,
    });
  }, [size, disabled, readonly, error]);

  const valueObs = useObservable(value$?.peek() || props.value || false);

  useObserve(() => {
    const nextVal = value$?.get();
    if (nextVal !== valueObs.peek()) {
      valueObs.set(nextVal || false);
      if (ref.current) {
        ref.current.checked = nextVal || false;
      }
    }
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.checked = valueObs.get();
    }
  }, [ref, valueObs]);

  return (
    <div className={fieldStyle}>
      <div className="flex h-6 items-center">
        <input
          ref={ref}
          id={fieldId}
          aria-describedby={description && `${fieldId}-description`}
          aria-errormessage={error && `${fieldId}-error`}
          name="comments"
          type="checkbox"
          className={clsx('form-checkbox', styles.checkbox)}
          required={required}
          onChange={(e) => {
            props.onChange?.(e);
          }}
          onBlur={(e) => {
            props.onBlur?.(e);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              e.currentTarget.blur();
            }
            props.onKeyDown?.(e);
          }}
          onInvalid={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <div className="ml-3 text-base leading-6">
        <label htmlFor={fieldId} className="text-gray-120">
          {props.label}
          {required && '*'}
        </label>
        {description && (
          <p id={`${fieldId}-description`} className="text-sm text-gray-90">
            {description}
          </p>
        )}
        {error && (
          <p id={`${fieldId}-error`} className="mt-1 text-sm text-red-90">
            {error}
          </p>
        )}
      </div>
    </div>
  );
}

export const OptimizedCheckboxField = memo(CheckboxField, (prevProps, nextProps) => {
  return (
    prevProps.id === nextProps.id &&
    prevProps.label === nextProps.label &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.readonly === nextProps.readonly &&
    prevProps.error === nextProps.error &&
    prevProps.description === nextProps.description &&
    prevProps.size === nextProps.size
  );
});
