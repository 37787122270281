export type DecodedJwt = {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  sub: string;
  /**
   * @description If the token is valid, this will be true
   */
  val: boolean;
};

export function parseJwt(token: string): DecodedJwt {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );
    const parsed = JSON.parse(jsonPayload);
    return {
      ...parsed,
      val: true,
    };
  } catch (e) {
    return {
      aud: '',
      exp: 0,
      iat: 0,
      iss: '',
      jti: '',
      sub: '',
      val: false,
    };
  }
}

/**
 *
 * @param token the jwt token or the jwt tokens expiration time
 * @param expirationOffset the offset in milliseconds
 * @returns {boolean} when true the token has expired
 */
export function hasJwtExpired(token: string | number, expirationOffset = 0) {
  let decodedExp: number;
  if (typeof token === 'string') {
    const { exp, val } = parseJwt(token);
    if (!val) return true;
    decodedExp = exp;
  } else {
    decodedExp = token;
  }
  return decodedExp * 1000 < Date.now() + expirationOffset;
}
