import { useNavigate, NavLink } from 'react-router-dom';

import { Trans, useIntl } from '@tiny-intl/react';
import { Form, Formik } from 'formik';
import { toast } from 'sonner';

import { requests } from '../../api';
import { Alert, Button, FormikField, FormikSubmit } from '../../components';
import { Page } from '../../features';
import { useAuth, useFormikValidation, useLocationSearchParams, validator } from '../../utils';

const successMessage = (text: string) => (
  <Alert className="mb-4" variant="success" icon="hi-mini-check-badge">
    {text}
  </Alert>
);

export function Signin() {
  const auth = useAuth();
  const { t } = useIntl();
  const navigate = useNavigate();
  const { email, signup, verified, requestedPasswordReset, passwordReset, changedEmail } =
    useLocationSearchParams<{
      signup: boolean;
      email: string;
      verified: boolean;
      requestedPasswordReset: boolean;
      passwordReset: boolean;
      changedEmail: boolean;
    }>();

  const validation = useFormikValidation({
    email: [validator.required(t('validation.required')), validator.email(t('validation.email'))],
    password: [validator.required(t('validation.required'))],
  });

  return (
    <Page title={t('signin')}>
      <h1 className="mb-6 select-none text-2xl font-bold">
        FilesGPT — <Trans name="signin" />
      </h1>
      {signup && (
        <Alert
          className="mb-4"
          variant="primary"
          icon="hi-mini-information-circle"
          title={t('signupCompleted')}
          actions={
            <button
              className="text-sm font-medium text-accent-120 transition hover:text-opacity-70"
              onClick={() => {
                if (!email) return;
                requests.account.resendVerificationEmail(email).catch(() => {});
                toast.success(t('resendedVerificationEmail'));
              }}
            >
              {t('resend')}
            </button>
          }
        >
          {t('sentVerificationLinkToEmail', { email })}
        </Alert>
      )}
      {verified !== undefined &&
        (verified ? (
          successMessage(t('emailWasVerified'))
        ) : (
          <Alert
            className="mb-4"
            variant="primary"
            icon="hi-mini-information-circle"
            title={t('verificationFailed')}
            actions={
              <button
                className="text-sm font-medium text-accent-120 transition hover:text-opacity-70"
                onClick={() => {
                  if (!email) return;
                  requests.account.resendVerificationEmail(email).catch(() => {});
                  toast.success(t('resendedVerificationEmail'));
                }}
              >
                {t('resend')}
              </button>
            }
          >
            {t('verificationFailedDescription')}
          </Alert>
        ))}
      {requestedPasswordReset && successMessage(t('requestedPasswordReset'))}
      {passwordReset && successMessage(t('successfulPasswordReset'))}
      {changedEmail && successMessage(t('successfulEmailChange'))}
      <Formik
        initialValues={{
          email: email || '',
          password: '',
        }}
        validate={validation.validate}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setStatus }) => {
          setStatus();
          try {
            const resp = await requests.auth.signin(values);
            auth.saveUser({
              userId: resp.user.id,
              refreshToken: resp.refreshToken,
              accessToken: resp.accessToken,
            });
            navigate(`/u/${resp.user.id}/documents`, {
              replace: true,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (err: any) {
            if (err.message) {
              setStatus(err.message);
            }
          }
        }}
      >
        {({ status, values, setStatus }) => (
          <Form className="flex flex-col gap-4">
            <FormikField
              {...validation.attr.email}
              is="TextField"
              name="email"
              label={t('email')}
              size="md"
              autoComplete="username"
            />
            <FormikField
              {...validation.attr.password}
              is="TextField"
              name="password"
              type="password"
              label={t('password')}
              autoComplete="current-password"
              size="md"
            />
            {status && (
              <div className="mt-1 text-base leading-4 text-red-100">
                {(() => {
                  switch (status) {
                    case 'NotVerified':
                      return (
                        <div>
                          <div>{t('loginErrors.notVerified')}</div>
                          <button
                            className="text-sm font-medium transition hover:underline"
                            onClick={() => {
                              requests.account.resendVerificationEmail(values.email);
                              setStatus();
                              toast.success(t('resendedVerificationEmail'));
                            }}
                          >
                            {t('resend')}
                          </button>
                        </div>
                      );
                    case 'NotAuthenticated':
                      return <div>{t('loginErrors.invalidLogin')}</div>;
                    default:
                      return <div>{t('loginErrors.unkown')}</div>;
                  }
                })()}
              </div>
            )}
            <NavLink className="text-gray-90 hover:underline" to="./reset-password">
              {t('forgotPassword')}
            </NavLink>
            <FormikSubmit variant="primary" size="md">
              <Trans name="signin" />
            </FormikSubmit>
            <div className="flex items-center">
              <hr className="flex-1" />
              <span className="px-4 text-gray-90">{t('dontHaveAnAccount')}</span>
              <hr className="flex-1" />
            </div>

            <Button variant="secondary" size="md" onClick={() => navigate('./signup')}>
              <Trans name="signup" />
            </Button>
          </Form>
        )}
      </Formik>
    </Page>
  );
}
