import type { DocumentSchema } from '@supertray/shared';

import { useNavigate, useParams } from 'react-router-dom';

import { For, Show, useComputed } from '@legendapp/state/react';
import { Trans, useIntl } from '@tiny-intl/react';
import { Form, Formik, useFormikContext } from 'formik';
import { toast } from 'sonner';

import { feathers, requests, store } from '../../api';
import { Button, FormikField, FormikSubmit } from '../../components';
import { FormLayout } from '../../components/FormLayout';
import { SchemaEditor } from '../../features';
import {
  createSchemaFields,
  toFeathersError,
  useAuth,
  useFormikValidation,
  validator,
} from '../../utils';

type FormValues = Pick<DocumentSchema, 'documentSchemaTemplateId' | 'name' | 'description'> & {
  schema: DocumentSchema['jsonSchema']['properties'];
};

function DocumentSchemaTemplate() {
  const auth = useAuth();
  const { t } = useIntl();
  const { values, setValues } = useFormikContext<FormValues>();

  const navigate = useNavigate();

  const [documentSchemaTemplates] = store.queryRows('document-schema-templates', {});
  const showDocumentSchemaTemplateSelect = useComputed(
    () => documentSchemaTemplates.get().length > 0,
  );

  const selectDocumentSchemaTemplate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    if (!value) {
      setValues({
        documentSchemaTemplateId: '',
        name: '',
        description: '',
        schema: {},
      });
    } else {
      const documentSchema = documentSchemaTemplates
        .get()
        .find((documentSchemaTemplate) => documentSchemaTemplate.id === value);
      if (documentSchema) {
        setValues({
          documentSchemaTemplateId: value,
          name: documentSchema.name,
          description: documentSchema.description || '',
          schema: documentSchema.jsonSchema.properties,
        });
      }
    }
  };

  const { templateId } = useParams();
  if (templateId) {
    showDocumentSchemaTemplateSelect.onChange((val) => {
      if (val) {
        selectDocumentSchemaTemplate({
          target: { value: templateId },
        } as React.ChangeEvent<HTMLSelectElement>);
      }
    });
  }

  const openFilePicker = () => {
    const element = document.getElementById('file-input');
    if (element) {
      element.click();
    }
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const form = new FormData();
    const file = e.target.files?.[0];
    if (!file) return;
    form.append('file', file);
    const accessToken = auth.accessToken.peek();

    if (!accessToken) return;
    toast.promise(requests.file.upload('document-schemas', accessToken, form), {
      loading: (
        <div>
          <div>{t('schemaTemplate.fromFile.processing')}...</div>
          <div className="font-normal">{t('schemaTemplate.fromFile.processingDescription')}</div>
        </div>
      ),
      // eslint-disable-next-line react/no-unstable-nested-components
      success: (data) => {
        const userId = auth.authenticatedUserId.get();

        return (
          <div className="flex items-center">
            <div>
              <div>{t('schemaTemplate.fromFile.success', { schemaName: data.name })}</div>
              <div className="font-normal">{t('schemaTemplate.fromFile.successDescription')}</div>
            </div>

            {userId && (
              <button
                className="!ml-2"
                data-button
                onClick={() => {
                  navigate(`/u/${userId}/schemas/edit/${data.id as string}`);
                  toast.dismiss();
                }}
              >
                {t('open')}
              </button>
            )}
          </div>
        );
      },
      error: t('generalError'),
      duration: 1000 * 60,
    });
    navigate('../schemas');
  };

  return (
    <Show if={showDocumentSchemaTemplateSelect}>
      <FormLayout.Section title={t('schemaTemplate.title')}>
        <div className="py-3">
          <p className="mb-2">
            <Trans name="schemaTemplate.select" />
          </p>
          <FormikField
            is="SelectField"
            name="documentSchemaTemplateId"
            label={t('schemaTemplate.title')}
            onChange={(e) => selectDocumentSchemaTemplate(e)}
          >
            <option label={`--- ${t('schemaTemplate.unselect')} ---`} value="" />
            <For each={documentSchemaTemplates}>
              {(documentSchemaTemplate) => (
                <option
                  key={documentSchemaTemplate.id.get()}
                  value={documentSchemaTemplate.id.get()}
                >
                  {documentSchemaTemplate.name.get()}
                </option>
              )}
            </For>
          </FormikField>
        </div>
        {!values.documentSchemaTemplateId && (
          <div className="max-w-md py-3">
            <p className="mb-1">
              <Trans name="schemaTemplate.fromFile.title" />
            </p>
            <p
              className="mb-1.5 text-base leading-5 text-gray-110"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('schemaTemplate.fromFile.description', {
                  pricingLink: 'https://filesgpt.eu',
                }),
              }}
            />
            <input
              id="file-input"
              type="file"
              className="absolute bottom-0 left-0 h-px w-px opacity-0"
              accept="image/png,image/jpeg,application/pdf"
              onChange={handleUpload}
            />

            <Button size="md" onClick={openFilePicker} aria-label="asd">
              {t('upload')}
            </Button>
          </div>
        )}
      </FormLayout.Section>
    </Show>
  );
}

export function CreateSchema() {
  const { t } = useIntl();
  const { accessToken } = useAuth();
  const navigate = useNavigate();

  const generalValidation = useFormikValidation<FormValues>({
    documentSchemaTemplateId: [],
    name: [validator.required(t('validation.required'))],
    description: [validator.required(t('validation.required'))],
    schema: [],
  });

  return (
    <>
      <div className="sticky top-12 z-[2] border-b border-gray-60 bg-gray-20/75 backdrop-blur-md">
        <div className="container">
          <h1 className="py-6 text-xl font-bold">
            <Trans name="createSchema.title" />
          </h1>
        </div>
      </div>
      <div className="container">
        <Formik<FormValues>
          enableReinitialize
          initialValues={{
            documentSchemaTemplateId: '',
            name: '',
            description: '',
            schema: {},
          }}
          validate={generalValidation.validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={async (values) => {
            const schema = createSchemaFields(values.schema);

            if (Object.keys(schema).length === 0) {
              toast.error(t('minSchemaFields'));
              return;
            }

            const payload: Partial<DocumentSchema> = {
              name: values.name,
              description: values.description || '',
              jsonSchema: {
                type: 'object',
                properties: schema,
              },
              documentSchemaTemplateId: values.documentSchemaTemplateId || null,
            };
            try {
              await feathers.service('document-schemas').create(payload, {
                headers: {
                  Authorization: `Bearer ${accessToken.peek()!}`,
                },
              });
              toast.success(t('createSchema.success'));
              navigate('../schemas');
            } catch (e) {
              const error = toFeathersError(e);
              if (error.feathers) {
                if (error.feathers.name === 'MaxTokenLimit') {
                  toast.error(t('schemaMaxTokenExceeded'));
                } else {
                  toast.error(error.feathers.name);
                }
              } else {
                toast.error(t('generalError'));
              }
            }
          }}
        >
          <Form>
            <FormLayout>
              <DocumentSchemaTemplate />
              <FormLayout.Section
                title={t('generalInformations')}
                description={t('createSchema.generalDescription')}
              >
                <div className="space-y-4">
                  <FormikField
                    {...generalValidation.attr.name}
                    is="TextField"
                    name="name"
                    label={t('name')}
                  />
                  <FormikField
                    {...generalValidation.attr.description}
                    is="TextArea"
                    name="description"
                    label={t('description')}
                    rows={4}
                  />
                </div>
              </FormLayout.Section>
              <FormLayout.Section title={t('schema')}>
                <SchemaEditor name="schema" />
              </FormLayout.Section>
            </FormLayout>
            <div className="sticky bottom-0 -mx-4 flex justify-end border-t border-gray-50 bg-gray-1/75 p-4 backdrop-blur-md md:-mx-6 md:px-6 lg:-mx-8 lg:px-8">
              <FormikSubmit variant="primary" size="md">
                {t('save')}
              </FormikSubmit>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}
