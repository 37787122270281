import type { Static } from '@feathersjs/typebox';

import { Type } from '@feathersjs/typebox';

import { supportedCountries } from './supported-countries';

const baseModel = Type.Object({
  id: Type.String({ format: 'uuid' }),
  updatedAt: Type.Date(),
  createdAt: Type.Date(),
});

export const baseField = Type.Object(
  {
    order: Type.Integer({ minimum: 1 }),
    title: Type.String({ minLength: 1 }),
    description: Type.String({ minLength: 1 }),
    default: Type.Optional(Type.String({ minLength: 1 })),
  },
  { additionalProperties: false },
);

export const textField = Type.Intersect([
  baseField,
  Type.Object({
    type: Type.Literal('string'),
  }),
]);
export type SchemaTextField = Static<typeof textField>;

export const dateField = Type.Intersect([
  baseField,
  Type.Object({
    type: Type.Literal('string'),
    format: Type.Union([Type.Literal('date'), Type.Literal('date-time')]),
  }),
]);
export type SchemaDateField = Static<typeof dateField>;

export const numberField = Type.Intersect([
  baseField,
  Type.Object({
    type: Type.Literal('number'),
  }),
]);
export type SchemaNumberField = Static<typeof numberField>;

export const selectField = Type.Intersect([
  baseField,
  Type.Object({
    type: Type.Literal('string'),
    oneOf: Type.Array(
      Type.Object({
        const: Type.String({ minLength: 1 }),
        title: Type.String({ minLength: 1 }),
      }),
    ),
  }),
]);
export type SchemaSelectField = Static<typeof selectField>;

export const arrayField = Type.Intersect([
  baseField,
  Type.Object({
    type: Type.Literal('array'),
    items: Type.Union([
      Type.Literal('string'),
      Type.Literal('number'),
      Type.Object({
        type: Type.Literal('string'),
        format: Type.Literal('date'),
      }),
      Type.Object({
        type: Type.Literal('string'),
        oneOf: Type.Array(
          Type.Object({
            const: Type.String({ minLength: 1 }),
            title: Type.String({ minLength: 1 }),
          }),
        ),
      }),
      Type.Object({
        type: Type.Literal('object'),
        properties: Type.Record(
          Type.String(),
          Type.Union([textField, dateField, numberField, selectField]),
        ),
      }),
    ]),
  }),
]);
export type SchemaArrayField = Static<typeof arrayField>;

export const objectField = Type.Intersect([
  baseField,
  Type.Object({
    type: Type.Literal('object'),
    properties: Type.Record(
      Type.String(),
      Type.Union([textField, dateField, numberField, selectField]),
    ),
  }),
]);
export type SchemaObjectField = Static<typeof objectField>;

export const schemaFields = Type.Union([
  textField,
  dateField,
  numberField,
  selectField,
  arrayField,
  objectField,
]);
export type SchemaFields = Static<typeof schemaFields>;

export const schemaFieldsRecord = Type.Record(Type.String(), schemaFields);
export type SchemaFieldsRecord = Static<typeof schemaFieldsRecord>;

export const documentJsonSchema = Type.Object({
  type: Type.Literal('object'),
  properties: schemaFieldsRecord,
});

export const documentSchemaSchema = Type.Intersect(
  [
    baseModel,
    Type.Object({
      userId: Type.String({ minLength: 1, format: 'uuid' }),
      name: Type.String({ minLength: 1 }),
      description: Type.Union([Type.Null(), Type.String({ minLength: 1 })]),
      default: Type.Optional(Type.String({ minLength: 1 })),
      jsonSchema: documentJsonSchema,
      documentSchemaTemplateId: Type.Union([Type.Null(), Type.String()]),
    }),
  ],
  {
    $id: 'DocumentSchema',
    additionalProperties: false,
  },
);
export type DocumentSchema = Static<typeof documentSchemaSchema>;

export const documentSchemaTemplate = Type.Intersect(
  [
    Type.Pick(documentSchemaSchema, ['name', 'description', 'jsonSchema']),
    Type.Object({
      id: Type.String({ minlength: 1 }),
      icon: Type.String({ minLength: 1 }),
      iconStyle: Type.Record(Type.String(), Type.String()),
    }),
  ],
  {
    $id: 'DocumentSchemaTemplate',
    additionalProperties: false,
  },
);

export type DocumentSchemaTemplate = Static<typeof documentSchemaTemplate>;

export const subscriptionSchema = Type.Intersect(
  [
    baseModel,
    Type.Object({
      userId: Type.String({ minLength: 1, format: 'uuid' }),
      sessionId: Type.String({ minLength: 1 }),
      stripeSubscriptionId: Type.Union([Type.Null(), Type.String({ minLength: 1 })]),
      stripeSubscriptionItemId: Type.Union([Type.Null(), Type.String({ minLength: 1 })]),
      status: Type.Union([
        Type.Literal('incomplete'),
        Type.Literal('active'),
        Type.Literal('pastDue'),
        Type.Literal('unpaid'),
        Type.Literal('canceled'),
      ]),
      canceledAt: Type.Union([Type.Null(), Type.Date()]),
      error: Type.Any(),
    }),
  ],
  {
    $id: 'Subscription',
    additionalProperties: false,
  },
);
export type Subscription = Static<typeof subscriptionSchema>;

export const supertrayTokenSchema = Type.Intersect(
  [
    baseModel,
    Type.Object({
      userId: Type.String({ minLength: 1, format: 'uuid' }),
      documentId: Type.Union([Type.String({ minLength: 1, format: 'uuid' }), Type.Null()]),
      type: Type.Union([
        Type.Literal('ocrPages'),
        Type.Literal('promtTokens'),
        Type.Literal('completionTokens'),
        Type.Literal('leftOverTokens'),
      ]),
      origin: Type.Union([
        Type.Literal('ocrRecognition'),
        Type.Literal('classification'),
        Type.Literal('dataExtraction'),
        Type.Literal('assistant'),
        Type.Literal('documentSchemaRecognition'),
        Type.Literal('tokenBilling'),
      ]),
      tokens: Type.Integer({ minimum: 0 }),
      billed: Type.Boolean(),
    }),
  ],
  {
    $id: 'SupertrayToken',
    additionalProperties: false,
  },
);
export type SupertrayToken = Static<typeof supertrayTokenSchema>;

export const userSchema = Type.Intersect(
  [
    baseModel,
    Type.Object({
      email: Type.String({ minLength: 1, format: 'email' }),
      password: Type.String({ minLength: 6, maxLength: 256 }),
      firstName: Type.String({ minLength: 1, maxLength: 256 }),
      lastName: Type.String({ minLength: 1, maxLength: 256 }),
      locale: Type.String({ minLength: 1 }),
      verified: Type.Boolean(),
      deactivated: Type.Boolean(),
      apiKey: Type.String(),
      stripeCustomerId: Type.Union([Type.Null(), Type.String()]),
      countryCode: Type.Union(supportedCountries.map((code) => Type.Literal(code))),
      subscriptionUsageLimit: Type.Union([Type.Null(), Type.Integer({ minimum: 100 })]),
      subscriptionId: Type.Union([Type.Null(), Type.String({ format: 'uuid' })]),
      agbsAcceptedAt: Type.Date(),
      privacyPolicyAcceptedAt: Type.Date(),
      maxDocumentProcessingsPerHalfMinute: Type.Integer({ minimum: 1, maximum: 100 }),
    }),
  ],
  {
    $id: 'User',
    additionalProperties: false,
  },
);
export type User = Static<typeof userSchema>;
