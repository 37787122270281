import type { TinyIntlDict } from '@tiny-intl/core';

export const supportedLocales = ['en-US', 'de-DE'] as const;

export type I18nSupportedLocales = (typeof supportedLocales)[number];

export const load = async (locale: I18nSupportedLocales) => {
  let messages: TinyIntlDict = {};

  if (locale === 'en-US') {
    messages = (await import('./entries/en-US')).default.messages;
  } else if (locale === 'de-DE') {
    messages = (await import('./entries/de-DE')).default.messages;
  }

  return {
    messages,
  };
};
