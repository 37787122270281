import { Outlet } from 'react-router-dom';

import { useIntl } from '@tiny-intl/react';

import { Account } from './Account';
import { Billing } from './Billing';
import { SubNavigation } from '../../../components';

export function Settings() {
  const { t } = useIntl();
  return (
    <div>
      <SubNavigation>
        <SubNavigation.Item href="account">{t('settings')}</SubNavigation.Item>
        <SubNavigation.Item href="billing">{t('billing')}</SubNavigation.Item>
      </SubNavigation>
      <Outlet />
    </div>
  );
}

Settings.Account = Account;
Settings.Billing = Billing;
