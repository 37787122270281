import { NavLink } from 'react-router-dom';

import clsx from 'clsx';

export type MainNavigationItemProps = {
  href: string;
  children: React.ReactNode;
};

export function MainNavigationItem(props: MainNavigationItemProps) {
  return (
    <li className="inline-flex">
      <NavLink
        to={props.href}
        className={({ isActive, isPending }) =>
          clsx(
            'select-none rounded-full px-3 py-1 text-base font-medium transition focus-visible:bg-gray-50 focus-visible:text-gray-120',
            {
              'bg-accent-90 text-white': isActive,
              'text-gray-120 hover:bg-gray-50 active:bg-gray-30': !isActive && !isPending,
            },
          )
        }
      >
        {props.children}
      </NavLink>
    </li>
  );
}
