import { useEffect, useState } from 'react';

import { useSelector } from '@legendapp/state/react';
import { Trans, useIntl } from '@tiny-intl/react';
import { Form, Formik } from 'formik';
import { toast } from 'sonner';

import { feathers, store } from '../../../api';
import { FormikField, FormikSubmit } from '../../../components';
import { FormLayout } from '../../../components/FormLayout';
import { Page } from '../../../features';
import { useAuth, useFormikValidation, validator } from '../../../utils';

type RechargeCreditForm = {
  priceId: string;
};

type SubscriptionUsageLimitForm = {
  subscriptionUsageLimit: number | string | null;
};

export function Billing() {
  const { t } = useIntl();
  const { authenticatedUserId, accessToken } = useAuth();

  const user = useSelector(() => {
    const userId = authenticatedUserId.get();
    if (!userId) return undefined;
    return store.getRow('users', userId).get();
  });
  const subscription = useSelector(() => {
    const userId = authenticatedUserId.get();
    if (!userId || !user?.subscriptionId) return undefined;

    return store.getRow('subscriptions', user?.subscriptionId).get();
  });

  const rechargeCreditValidaiton = useFormikValidation<RechargeCreditForm>({
    priceId: [validator.required(t('validation.required'))],
  });

  const subscriptionUsageLimitValidation = useFormikValidation<SubscriptionUsageLimitForm>({
    subscriptionUsageLimit: [],
  });

  const [totalCreditBalance, setTotalCreditBalance] = useState(0);
  const [creditPackages, setCreditPackages] = useState<{ priceId: string; amount: number }[]>([]);
  const [totalSubscriptionUsage, setTotalSubscriptionUsage] = useState(0);

  const [loading, setLoading] = useState(true);

  const params = {
    route: { userId: authenticatedUserId.get() },
    headers: {
      Authorization: `Bearer ${accessToken.get() || ''}`,
    },
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const [totalCreditsResp, creditPackagesResp, totalSubscriptionUsageResp] = await Promise.all([
        feathers.service('users/:userId/credit-balance').find(params),
        feathers.service('users/:userId/credit-balance').get('list', params),
        feathers.service('users').getTotalSubscriptionUsage(undefined, params),
      ]);

      setTotalCreditBalance(totalCreditsResp.totalCreditBalance);
      setTotalSubscriptionUsage(totalSubscriptionUsageResp.totalUsagePriceWithoutTax);
      setCreditPackages(creditPackagesResp);
      setLoading(false);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eurPrice = (intPrice: number) => `${Math.round(intPrice) / 100}€`;

  return (
    <Page intlTitle="billing">
      <FormLayout className="container">
        {loading ? (
          <div className="mt-3 text-center">{t('loading')}...</div>
        ) : (
          <div>
            <FormLayout.Section title={t('billing.creditBalance')}>
              <div>
                <div className="py-3">
                  <p className="font-bold">{eurPrice(totalCreditBalance)}</p>
                  <p className="mt-1 whitespace-pre-line text-base leading-5 text-gray-110">
                    <Trans name="billing.creditBalanceDescription" />
                  </p>
                </div>
                {!user?.subscriptionId && (
                  <Formik
                    initialValues={{
                      priceId: creditPackages.sort((a, b) => a.amount - b.amount)[0].priceId,
                    }}
                    validate={rechargeCreditValidaiton.validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                      const { url } = await feathers.service('users/:userId/credit-balance').create(
                        {
                          priceId: values.priceId,
                        },
                        params,
                      );
                      window.location.href = url;
                    }}
                  >
                    <Form>
                      <div className="grid grid-cols-1 gap-4">
                        <div>
                          <p className="mb-2">
                            <Trans name="billing.creditPackage.description" />:
                          </p>
                          <FormikField
                            is="SelectField"
                            name="priceId"
                            label={t('billing.creditPackage')}
                            description={`(${t('billing.creditPackage.taxExclusive')})`}
                          >
                            {creditPackages.map((creditPackage) => (
                              <option key={creditPackage.priceId} value={creditPackage.priceId}>
                                {eurPrice(creditPackage.amount)}
                              </option>
                            ))}
                          </FormikField>
                        </div>

                        <div className="inline-flex justify-self-end">
                          <FormikSubmit size="md">
                            {t('billing.creditPackage.recharge')}
                          </FormikSubmit>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                )}
              </div>
            </FormLayout.Section>
            <FormLayout.Section title={t('billing.subscription')}>
              {!user?.subscriptionId ? (
                <Formik
                  initialValues={{}}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={async () => {
                    const { url } = await feathers.service('subscriptions').create({}, params);
                    window.location.href = url;
                  }}
                >
                  <Form>
                    <div className="py-3">
                      <p className="mb-2">
                        <Trans name="billing.subscription.subscribe.clickButtonDescription" />
                      </p>
                      <FormikSubmit>{t('billing.subscription.subscribe')}</FormikSubmit>
                      <p
                        className="mt-2 whitespace-pre-line text-base leading-5 text-gray-110"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: t('billing.subscription.subscribe.description', {
                            pricingLink: 'https://filesgpt.eu/#pricing',
                          }),
                        }}
                      />
                    </div>
                  </Form>
                </Formik>
              ) : (
                <div className="py-3">
                  {user && (
                    <div>
                      <div>
                        <span>{`${t('billing.subscription.status')}`}: </span>
                        <span className="font-bold">
                          {t(
                            `billing.subscription.status.${
                              subscription?.canceledAt && subscription?.status === 'active'
                                ? 'precanceled'
                                : subscription?.status || ''
                            }`,
                          )}
                        </span>
                      </div>

                      <div>
                        <span>{`${t('billing.subscription.totalUsageThisMonth')}`}: </span>
                        <span className="font-bold">{eurPrice(totalSubscriptionUsage)}</span>
                      </div>
                      <p className="whitespace-pre-line text-base leading-5 text-gray-110">
                        <Trans name="billing.subscription.useStripePortal" />
                      </p>
                      <div className="mt-3">
                        <Formik<SubscriptionUsageLimitForm>
                          initialValues={{
                            subscriptionUsageLimit: user.subscriptionUsageLimit
                              ? (user.subscriptionUsageLimit / 100 / 100).toFixed(2)
                              : null,
                          }}
                          validate={subscriptionUsageLimitValidation.validate}
                          validateOnChange={false}
                          validateOnBlur={false}
                          onSubmit={async (values) => {
                            let price: number | null = null;
                            if (typeof values.subscriptionUsageLimit === 'string') {
                              price = parseFloat(values.subscriptionUsageLimit.replace('€', ''));
                            } else {
                              price = values.subscriptionUsageLimit;
                            }

                            await feathers.service('users').patch(
                              user.id,
                              {
                                subscriptionUsageLimit: price
                                  ? Math.round(price * 100 * 100)
                                  : null,
                              },
                              params,
                            );
                            toast.success(t('billing.subscription.usageLimit.updated'));
                          }}
                        >
                          <Form className="grid grid-cols-1 gap-4">
                            <FormikField
                              {...subscriptionUsageLimitValidation.attr.subscriptionUsageLimit}
                              is="CurrencyField"
                              name="subscriptionUsageLimit"
                              size="md"
                              label={t('billing.subscription.usageLimit')}
                              description={t('billing.subscription.usageLimit.description')}
                            />
                            <div className="inline-flex justify-self-end">
                              <FormikSubmit size="md">{t('save')}</FormikSubmit>
                            </div>
                          </Form>
                        </Formik>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </FormLayout.Section>
            <FormLayout.Section title={t('billing.stripePortal')}>
              <div className="py-3">
                <Formik
                  initialValues={{}}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={async () => {
                    const { url } = await feathers
                      .service('users/:userId/stripe-portal')
                      .find(params);
                    window.location.href = url;
                  }}
                >
                  <Form>
                    <p className="mb-2 whitespace-pre-line">
                      <Trans name="billing.stripePortal.clickButtonDescription" />
                    </p>
                    <FormikSubmit>{t('billing.stripePortal.toPortal')}</FormikSubmit>
                    <p className="mt-2 whitespace-pre-line text-base leading-5 text-gray-110">
                      <Trans name="billing.stripePortal.description" />
                    </p>
                  </Form>
                </Formik>
              </div>
            </FormLayout.Section>
          </div>
        )}
      </FormLayout>
    </Page>
  );
}
