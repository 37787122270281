/* eslint-disable react/jsx-props-no-spreading */
import type { BaseFieldProps } from './BaseField';
import type { FormControlFieldProps } from './TextField';

import { memo, useEffect, useId, useMemo, useRef } from 'react';

import { observer, useObservable, useObserve } from '@legendapp/state/react';

import { BaseField } from './BaseField';
import styles from './BaseField.module.css';
import { BaseFieldLabel } from './BaseFieldLabel';
import { mergeProps } from '../../utils';

type TextAreaInput = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export type TextAreaProps = Omit<BaseFieldProps, 'children' | 'fieldId'> &
  FormControlFieldProps<string> & {
    autoComplete?:
      | 'on'
      | 'off'
      | 'name'
      | 'honorific-prefix'
      | 'given-name'
      | 'additional-name'
      | 'family-name'
      | 'honorific-suffix'
      | 'nickname'
      | 'username'
      | 'new-password'
      | 'current-password'
      | 'one-time-code';
  } & Omit<TextAreaInput, 'type' | 'size' | 'placeholder' | 'autoComplete' | 'ref'>;

export const TextArea = observer((props: TextAreaProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const {
    size,
    id,
    label,
    required,
    placeholder,
    autoComplete,
    disabled,
    readonly,
    value$,
    error,
    description,
    className,
    ...htmlInput
  } = mergeProps(props, {
    id: useId(),
    autoComplete: 'off',
    readonly: false,
    disabled: false,
  });

  const errorMessage = useMemo(() => {
    return error ? `${id}-err` : undefined;
  }, [error, id]);
  const descriptedBy = useMemo(() => {
    return description ? `${id}-desc` : undefined;
  }, [description, id]);
  const fieldPlaceholder = useMemo(() => {
    if (placeholder && !label && required) return `${placeholder}*`;
    return placeholder || undefined;
  }, [placeholder, label, required]);
  const valueObs = useObservable(value$?.peek() || props.value || '');

  useObserve(() => {
    const nextVal = value$?.get();
    if (nextVal !== valueObs.peek()) {
      valueObs.set(nextVal || '');
      if (ref.current) {
        ref.current.value = nextVal || '';
      }
    }
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.value = valueObs.get();
    }
  }, [ref, valueObs]);

  return (
    <BaseField
      fieldId={id}
      disabled={disabled}
      readonly={readonly}
      size={size}
      error={error}
      description={description}
      className={className}
      fieldClassName={styles.asTextArea}
    >
      {label && (
        <BaseFieldLabel forId={id} required={required}>
          {label}
        </BaseFieldLabel>
      )}
      <textarea
        {...htmlInput}
        ref={ref}
        id={id}
        placeholder={fieldPlaceholder}
        required={required}
        autoComplete={autoComplete}
        disabled={disabled}
        readOnly={readonly}
        aria-invalid={!!errorMessage}
        aria-errormessage={errorMessage}
        aria-describedby={descriptedBy}
        onChange={(e) => {
          props.onChange?.(e);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            e.currentTarget.blur();
          }
          props.onKeyDown?.(e);
        }}
        onInvalid={(e) => {
          e.preventDefault();
        }}
      />
    </BaseField>
  );
});

export const OptimizedTextArea = memo(TextArea, (prevProps, nextProps) => {
  return (
    prevProps.id === nextProps.id &&
    prevProps.label === nextProps.label &&
    prevProps.required === nextProps.required &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.autoComplete === nextProps.autoComplete &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.readonly === nextProps.readonly &&
    prevProps.error === nextProps.error &&
    prevProps.description === nextProps.description &&
    prevProps.size === nextProps.size
  );
});
