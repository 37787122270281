import type { CheckboxFieldProps } from './CheckboxField';
import type { CurrencyFieldProps } from './CurrencyField';
import type { SelectFieldProps } from './SelectField';
import type { TextAreaProps } from './TextArea';
import type { ValidateFn } from '../../utils';

import { createElement, useEffect, useMemo } from 'react';

import { useObservable } from '@legendapp/state/react';
import { useField } from 'formik';

import { OptimizedCheckboxField } from './CheckboxField';
import { OptimizedCurrencyField } from './CurrencyField';
import { OptimizedSelectField } from './SelectField';
import { OptimizedTextArea } from './TextArea';
import { type TextFieldProps, OptimizedTextField } from './TextField';

type AsTextField = {
  is: 'TextField';
} & TextFieldProps;

type AsTextArea = {
  is: 'TextArea';
} & TextAreaProps;

type AsSelectField = {
  is: 'SelectField';
} & SelectFieldProps;

type AsCheckboxField = {
  is: 'CheckboxField';
} & CheckboxFieldProps & {
    placeholder?: undefined;
    htmlSize?: undefined;
  };

type AsCurrencyField = {
  is: 'CurrencyField';
} & CurrencyFieldProps;

export type FormikFieldProps = {
  name: string;
  onValidate?: ValidateFn['validate'];
} & (
  | Omit<AsTextField, 'value' | 'value$'>
  | Omit<AsCheckboxField, 'value' | 'value$'>
  | Omit<AsTextArea, 'value' | 'value$'>
  | Omit<AsSelectField, 'value' | 'value$'>
  | Omit<AsCurrencyField, 'value' | 'value$'>
);

const components = {
  TextField: OptimizedTextField,
  CheckboxField: OptimizedCheckboxField,
  TextArea: OptimizedTextArea,
  SelectField: OptimizedSelectField,
  CurrencyField: OptimizedCurrencyField,
};

export function FormikField(props: FormikFieldProps) {
  const { is, onValidate, error: cError, ...componentProps } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ value, ...field }, meta, helper] = useField({
    name: props.name,
  });

  const value$ = useObservable(meta.initialValue);
  const checked$ = useObservable(meta.initialValue === true);

  useEffect(() => {
    value$.set(value);
  }, [value, value$]);

  const validate = (nextValue: any) => {
    if (!onValidate) return;
    const nextError = onValidate(nextValue);
    helper.setError(nextError);
  };

  const onChange = (e: React.ChangeEvent<any>) => {
    componentProps.onChange?.(e);
    if (meta.error && is === 'TextField') {
      validate(e.target.value);
    }
    if (is === 'CheckboxField') {
      validate(e.target.checked);
    }
  };

  const onBlur = (e: React.FocusEvent<any>) => {
    if (is === 'TextField') {
      validate(e.target.value);
    } else if (is === 'CheckboxField') {
      validate(e.target.checked);
    }
    helper.setValue(e.target.value);
    componentProps.onBlur?.(e);
    field.onBlur(e);
  };

  const onKeyDown = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      helper.setValue(e.currentTarget.value);
    } else if (meta.error && is === 'TextField') {
      validate(e.currentTarget.value);
    } else if (meta.error && is === 'CheckboxField') {
      validate(e.currentTarget.checked);
    }
  };

  const error = useMemo(() => {
    return meta.error || cError;
  }, [meta.error, cError]);

  useEffect(() => {
    if (componentProps.label && error) {
      validate(value$.peek());
    }
  }, [componentProps.label, componentProps.placeholder]); // eslint-disable-line react-hooks/exhaustive-deps

  if (is === 'TextField') {
    const properties = componentProps as TextFieldProps;
    return createElement(components[is], {
      ...properties,
      ...field,
      label: props.label,
      error,
      value: undefined,
      value$,
      onChange,
      onBlur,
      onKeyDown,
    });
  }

  if (is === 'CheckboxField') {
    return createElement(components[is], {
      ...componentProps,
      ...field,
      error,
      value: undefined,
      value$: checked$,
      onChange,
      onBlur,
      onKeyDown,
    });
  }

  if (is === 'TextArea') {
    const properties = componentProps as TextAreaProps;
    return createElement(components[is], {
      ...properties,
      ...field,
      label: props.label,
      error,
      value: undefined,
      value$,
      onChange,
      onBlur,
      onKeyDown,
    });
  }

  if (is === 'SelectField') {
    const properties = componentProps as SelectFieldProps;
    return createElement(components[is], {
      ...properties,
      ...field,
      label: props.label,
      error,
      value,
      value$,
      onChange,
      onBlur,
      onKeyDown,
    });
  }

  if (is === 'CurrencyField') {
    const properties = componentProps as CurrencyFieldProps;
    return createElement(components[is], {
      ...properties,
      ...field,
      label: props.label,
      error,
      value: undefined,
      value$,
      onChange,
      onBlur,
      onKeyDown,
    });
  }

  return null;
}
