import type { DocumentItem } from '../../api/types';
import type { QueryParams } from '@tabular-state/store';

import { observer } from '@legendapp/state/react';

import { store } from '../../api';

export const DocumentsList = observer((props: { query: QueryParams<DocumentItem> }) => {
  const [data] = store.queryRows('documents', props.query);

  return (
    <div>
      {data.get().map((doc) => (
        <div key={doc.id}>
          {doc.id} {doc.fileName}
        </div>
      ))}
    </div>
  );
});
