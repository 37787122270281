import type { UserItem } from '../types';

import { debug } from '../../utils';

export type SignupData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  locale: 'de' | 'en';
  terms?: undefined;
  privacy?: undefined;
};

export type SigninData = {
  email: string;
  password: string;
};

const headers = {
  'Content-Type': 'application/json',
};

const url = (path: string) => `${import.meta.env.VITE_APP_API_URL}/${path}`;
async function handleResponse<T = any>(response: Response) {
  const json = await response.json();
  if (response.ok) {
    return json as T;
  }

  debug.error('Error during request', json);

  throw new Error(json.name || 'Error during request');
}

export const auth = {
  signin: async (data: SigninData) => {
    const response = await fetch(url('authentication'), {
      method: 'POST',
      headers,
      body: JSON.stringify({
        ...data,
        strategy: 'local',
      }),
    });
    return handleResponse<{
      accessToken: string;
      refreshToken: string;
      user: UserItem;
    }>(response);
  },
};

export const account = {
  signup: async (data: SignupData) => {
    const response = await fetch(url('accounts'), {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
    return handleResponse<UserItem>(response);
  },
  verifyEmail: async (token: string) => {
    const response = await fetch(url('accounts'), {
      method: 'PUT',
      headers,
      body: JSON.stringify({ action: 'verifyEmail', token }),
    });
    return handleResponse<{
      action: 'verifyEmail';
      success: boolean;
    }>(response);
  },
  resendVerificationEmail: async (email: string) => {
    const response = await fetch(url('accounts'), {
      method: 'PUT',
      headers,
      body: JSON.stringify({ action: 'resendVerificationEmail', email }),
    });

    return handleResponse<{
      action: 'verifyEmail';
      success: boolean;
    }>(response);
  },
  sendEmailChangeMail: async (accessToken: string) => {
    const response = await fetch(url('accounts'), {
      method: 'PUT',
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ action: 'sendEmailChange' }),
    });
    return handleResponse<{
      action: 'sendEmailChange';
      success: boolean;
    }>(response);
  },
  changeEmail: async (data: { newEmail: string; token: string }) => {
    const response = await fetch(url('accounts'), {
      method: 'PUT',
      headers,
      body: JSON.stringify({ action: 'changeEmail', ...data }),
    });
    return handleResponse<{
      action: 'changeEmail';
      success: boolean;
    }>(response);
  },
  sendResetPasswordMail: async (email: string) => {
    const response = await fetch(url('accounts'), {
      method: 'PUT',
      headers,
      body: JSON.stringify({ action: 'sendPasswordReset', email }),
    });
    return handleResponse<{
      action: 'sendPasswordReset';
      success: boolean;
    }>(response);
  },
  resetPassword: async (data: { newPassword: string; token: string }) => {
    const response = await fetch(url('accounts'), {
      method: 'PUT',
      headers,
      body: JSON.stringify({ action: 'resetPassword', ...data }),
    });
    return handleResponse<{
      action: 'resetPassword';
      success: boolean;
    }>(response);
  },
};

export const file = {
  upload: async (path: string, accessToken: string, formData: FormData) => {
    const response = await fetch(url(path), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });
    return handleResponse<{
      id: string;
      url: string;
    }>(response);
  },
};
