import { useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { For, Show, useComputed, useObservable } from '@legendapp/state/react';
import { Trans, useIntl } from '@tiny-intl/react';
import { toast } from 'sonner';

import { DocumentDetail } from './DocumentDetail';
import { requests, store } from '../../api';
import { Button, Table } from '../../components';
import { Page } from '../../features';
import { useAuth } from '../../utils';

export function DocumentRows() {
  const { t } = useIntl();
  const auth = useAuth();
  const navigate = useNavigate();
  const userId = auth.authenticatedUserId.get();
  const formRef = useRef<HTMLFormElement>(null);

  const [data] = store.queryRows('documents', {
    query: {
      actions: {
        classification: { enabled: true },
        dataExtraction: { enabled: true },
      },
      deleted: false,
    },
  });
  const state = useObservable({
    loaded: false,
  });
  const showEmpty = useComputed(() => data.get().length === 0 && state.loaded.get());

  const [documentSchemas] = store.queryRows('document-schemas', {});
  const documentSchemaExists = useComputed(() => documentSchemas.get().length > 0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      state.loaded.set(true);
    }, 100);
    return () => clearTimeout(timeout);
  }, [state]);

  const openFilePicker = () => {
    const element = document.getElementById('file-input');
    if (element) {
      element.click();
    }
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const form = new FormData();
    const file = e.target.files?.[0];
    if (!file) return;
    form.append('file', file);
    form.append('deleteSensitiveDataAfterProcessing', 'false');
    const accessToken = auth.accessToken.peek();
    if (!accessToken) return;

    if (documentSchemaExists.get()) {
      toast.promise(requests.file.upload('documents', accessToken, form), {
        loading: `${t('documentProcessing')}...`,
        // eslint-disable-next-line react/no-unstable-nested-components
        success: (data) => {
          const userId = auth.authenticatedUserId.get();
          store.queryRows('documents', {
            query: {
              actions: { classification: { enabled: true }, dataExtraction: { enabled: true } },
              deleted: false,
            },
          });

          return (
            <div className="flex items-center">
              <div>
                <div>{t('documentWasProcessed', { name: data.fileName })}</div>
              </div>

              {userId && (
                <button
                  className="!ml-2"
                  data-button
                  onClick={() => {
                    navigate(`/u/${userId}/documents/${data.id as string}`);
                    toast.dismiss();
                  }}
                >
                  {t('open')}
                </button>
              )}
            </div>
          );
        },
        error: (error) => {
          if (error.message === 'CreditBalanceUsedUp') {
            return t('creditBalanceUsedUp');
          }
          if (error.message === 'SubscriptionUsageLimitExceeded') {
            return t('useageLimitExceeded');
          }
          if (error.message === 'TooManyRequests') {
            return t('tooManyDocumentProcessingRequests');
          }
          return t('generalError');
        },
      });
    } else {
      toast.error(
        <div className="flex items-center">
          <div className="font-normal">{t('missingSchema')}</div>

          {userId && (
            <button
              className="!ml-2"
              data-button
              onClick={() => {
                navigate(`/u/${userId}/schemas`);
                toast.dismiss();
              }}
            >
              {t('create')}
            </button>
          )}
        </div>,
      );
    }
  };

  return (
    <>
      <Table.Intro title={t('manageDocuments')} className="mt-2">
        <Button variant="primary" onClick={openFilePicker}>
          <Trans name="upload" />
        </Button>
      </Table.Intro>
      <Table.Card>
        <Table>
          <Table.Head>
            <Table.Head.Column first>
              <Trans name="name" />
            </Table.Head.Column>
            <Table.Head.Column>ID</Table.Head.Column>
            <Table.Head.Column align="right">
              <span className="sr-only">
                <Trans name="actions" />
              </span>
            </Table.Head.Column>
          </Table.Head>
          <Table.Body>
            <For each={data} optimized>
              {(row) => (
                <Table.Row key={row.id.get()} onClick={() => {}}>
                  <Table.Row.Column first>{row.fileName.get()}</Table.Row.Column>
                  <Table.Row.Column>
                    <span className="text-mono text-xs text-gray-110">{row.id.get()}</span>
                  </Table.Row.Column>
                  <Table.Row.Column align="right" last>
                    <NavLink
                      to={`./${row.id.get()}`}
                      className="select-none font-medium text-accent-100 hover:text-accent-110"
                    >
                      <Trans name="open" />
                      <span className="sr-only">, {row.fileName.get()}</span>
                    </NavLink>
                  </Table.Row.Column>
                </Table.Row>
              )}
            </For>
          </Table.Body>
        </Table>
        <Show if={showEmpty}>
          <div className="border-t-[1px] border-gray-60 py-3.5 px-3 text-center text-sm text-gray-110">
            {t('noDocumentsUploaded')}
          </div>
        </Show>
      </Table.Card>
      <form ref={formRef} encType="multipart/form-data">
        <input
          id="file-input"
          type="file"
          className="absolute bottom-0 left-0 h-px w-px opacity-0"
          accept="image/png,image/jpeg,application/pdf"
          onChange={handleUpload}
        />
      </form>
    </>
  );
}

export function Documents() {
  return (
    <Page intlTitle="document" intlCount={10}>
      <div className="container">
        <DocumentRows />
      </div>
    </Page>
  );
}

Documents.Document = DocumentDetail;
