import type { ObservablePrimitive } from '@legendapp/state';

import { batch, computed, observable } from '@legendapp/state';

import { wait } from './wait';
import { debug } from '../devtools';

export type QueueRunner = () => Promise<any> | any;

export type QueueJob = {
  id: string;
  run: QueueRunner;
  date: number;
  prio: number;
  wait: number;
  name?: string;
};

export type Queue = {
  active: ObservablePrimitive<boolean>;
  start: () => void;
  stop: () => void;
  enqueue: (
    fn: QueueRunner,
    options?: {
      /**
       * @default 30
       */
      prio?: number;
      wait?: number;
      name?: string;
    },
  ) => void;
};

export function createQueue(options?: {
  autostart?: number;
  wait?: number;
  parallel?: number;
}): Queue {
  let id = 0;
  const props = {
    autostart: undefined,
    wait: 100,
    ...options,
  };
  const queueStack: QueueJob[] = [];

  const lastEdited = observable(0);
  const notifier = observable(0);
  const active = observable(false);

  const queue = computed(() => {
    lastEdited.get();
    return queueStack.sort((a, b) => {
      if (a.prio === b.prio) return a.date - b.date;
      return a.prio - b.prio;
    });
  });

  const nextJob = computed(() => {
    notifier.get();
    if (!active.get()) return undefined;
    return queue.get().at(0)?.id;
  });

  function shift(jobId: string) {
    const index = queueStack.findIndex((j) => j.id === jobId);
    if (index > -1) {
      queueStack.splice(index, 1);
    }
  }

  function notify() {
    notifier.set((curr) => curr + 1);
  }

  async function runJob() {
    const job = queue.peek().at(0);
    if (!job) return;
    const mark = performance.now();
    try {
      const res = await job.run();
      debug.debug('RAN JOB', {
        ...job,
        empty: !res,
      });
      if (((props.wait !== undefined && props.wait > 0) || job.wait > 0) && res !== null) {
        await wait(job.wait || props.wait);
      }
      debug.debug('AFTER JOB', {
        ...job,
        duration: `${(performance.now() - mark).toFixed(2)}ms`,
        empty: !res,
      });
    } catch (e) {
      debug.error('ERROR JOB', {
        ...job,
        error: e,
      });
    }
    shift(job.id);
    if (queueStack.length > 0) {
      notify();
    } else {
      notifier.set(0);
    }
  }

  nextJob.get();
  nextJob.onChange((next, getPrev) => {
    if (next && next !== getPrev()) {
      runJob().catch((err) => {
        debug.error('ERROR RUNNING JOB', err);
      });
    }
  });

  return {
    active,
    start: () => {
      if (active.peek()) return;
      batch(() => {
        active.set(true);
        notify();
        debug.debug('Started Queue');
      });
    },
    stop: () => {
      if (!active.peek()) return;
      batch(() => {
        active.set(false);
        notifier.set(0);
        debug.debug('Stopped Queue');
      });
    },
    enqueue: (fn, opts) => {
      id += 1;
      queueStack.push({
        id: `q-${id}`,
        run: fn,
        date: Date.now(),
        prio: opts?.prio || 30,
        wait: opts?.wait || 0,
        name: opts?.name,
      });
      lastEdited.set(id);
      if (notifier.peek() === 0 && active.peek()) {
        notify();
      }
    },
  };
}
