import type { ApiServices } from './feathers';

import { createStore } from '@tabular-state/store';

// import { createMongoAbilityFromPermissions, debug, setGlobalAbilities } from '../utils';

export type StoreTables = {
  [K in keyof ApiServices]: {
    idField: string;
    item: ApiServices[K]; // eslint-disable-line no-use-before-define
  };
};

export const store = createStore<StoreTables>();

// store.plugin({
//   mount: () => {
//     let timeout = 0;
//     const dispose = store.getTable('permissions').onChange((table) => {
//       if (timeout) clearTimeout(timeout);
//       timeout = window.setTimeout(() => {
//         const values = Object.values(table);
//         const abilities = createMongoAbilityFromPermissions(values);
//         setGlobalAbilities(abilities);
//         debug.debug('Global abilities updated', abilities.rules.length);
//       }, 10);
//     });
//     return dispose;
//   },
// });
