import clsx from 'clsx';

import styles from './MainNavigation.module.css';
import { MainNavigationItem } from './MainNavigationItem';

export type MainNavigationProps = {
  children: React.ReactNode;
  logo?: React.ReactNode;
  right?: React.ReactNode;
};

export function MainNavigation(props: MainNavigationProps) {
  return (
    <div
      id="main-nav"
      role="navigation"
      aria-label="Main Navigation"
      className={clsx(
        'sticky top-0 z-20 h-12 bg-gray-20/75 py-2 backdrop-blur-md',
        styles.mainNavigation,
      )}
    >
      <div className="container h-full">
        <div className="flex h-full items-center justify-between">
          <div className="flex items-center">
            {props.logo}
            <ul className="flex items-center gap-1">{props.children}</ul>
          </div>
          <div className="flex items-center gap-2">{props.right}</div>
        </div>
      </div>
    </div>
  );
}

MainNavigation.Item = MainNavigationItem;
