import type { Database } from '@tabular-state/database';

import { createIndexedDbAdapter } from '@tabular-state/database';

import { store } from './store';
import { debug } from '../utils';

let database: Database | undefined;
let disposeDatabase: (() => void) | undefined;
export const setDatabase = (userId: string | null, cb?: () => void) => {
  if (userId === null) {
    disposeDatabase?.();
    disposeDatabase = undefined;
    database = undefined;
    return;
  }
  const newNs = `account-${userId}`;
  if (database && database.namespace === newNs) return;
  if (!database) {
    database = createIndexedDbAdapter({
      namespace: newNs,
      autoPersistTables: [
        ['users', 'id'],
        ['permissions', 'id'],
        ['document-schemas', 'id'],
        ['document-schema-templates', 'id'],
        ['documents', 'id'],
        ['supertray-tokens', 'id'],
      ],
      onRevalidate(tableName, itemIds) {
        debug.log('Revalidating', tableName, itemIds);
      },
    });
    store.plugin({
      mount: (s) => {
        disposeDatabase = database?.mount(s);
        return () => {
          disposeDatabase?.();
        };
      },
    });
  } else {
    database.setNamespace(newNs);
  }
  cb?.();
};

export const getDatabase = () => database;
