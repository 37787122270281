import type { DocumentItem } from './types';
import type { Any } from '../types';
import type { Params, Service } from '@feathersjs/feathers/lib';
import type { RestService } from '@feathersjs/rest-client';
import type {
  DocumentSchema,
  Subscription,
  SupertrayToken,
  User,
  DocumentSchemaTemplate,
} from '@supertray/shared';
import type { FeathersTabularStateAdapterService } from '@tabular-state/api-feathers';

import { feathers as createFeathers } from '@feathersjs/feathers/lib';
import rest from '@feathersjs/rest-client';
import { observable } from '@legendapp/state';
import { createFeathersClientAdapter, toFeathersQuery } from '@tabular-state/api-feathers';

import { store } from './store';
import { createQueue, debug, wait } from '../utils';

export type ApiServices = {
  'users': User;
  'documents': DocumentItem;
  'document-schemas': DocumentSchema;
  'supertray-tokens': SupertrayToken;
  'document-schema-templates': DocumentSchemaTemplate;
  'subscriptions': Subscription;
};

type ServiceTypes = {
  'authentication': Service<Any>;
  'users': RestService<
    ApiServices['users'],
    Partial<ApiServices['users']> & { refreshApiKey?: boolean }
  > & {
    getTotalSubscriptionUsage(
      data: undefined,
      params: Params,
    ): Promise<{ totalUsagePriceWithoutTax: number }>;
  };
  'users/:userId/credit-balance': Service<Any>;
  'users/:userId/stripe-portal': Service<Any>;
  'documents': RestService<ApiServices['documents']>;
  'document-schemas': RestService<ApiServices['document-schemas']>;
  'supertray-tokens': RestService<ApiServices['supertray-tokens']>;
  'document-schema-templates': RestService<ApiServices['document-schema-templates']>;
  'subscriptions': RestService<ApiServices['subscriptions']>;
};

export const restClient = rest<ServiceTypes>(import.meta.env.VITE_APP_API_URL).fetch(
  window.fetch.bind(window),
);

export const feathers = createFeathers<ServiceTypes>();

feathers.configure(restClient);
feathers.use('users', restClient.service('users'), {
  methods: ['find', 'get', 'create', 'update', 'patch', 'remove', 'getTotalSubscriptionUsage'],
});

let apiAccessToken: string | null = null;
export const setAccessToken = (accessToken: string) => {
  apiAccessToken = accessToken;
};

export const apiQueue = createQueue({
  wait: 100,
});

const connectionState = observable(0);
const connected = observable(false);

export const apiContext = {
  connected,
  connectionState,
};

const onConnected = () => {
  connectionState.set(Date.now());
  connected.set(true);
};

const onDisconnected = () => {
  connected.set(false);
  apiQueue.stop();
};

wait(50).then(() => {
  if (navigator.onLine) {
    onConnected();
  }
});

window.addEventListener('online', () => {
  onConnected();
});

window.addEventListener('offline', () => {
  onDisconnected();
});

const stateServices: (keyof ApiServices)[] = [
  'users',
  'document-schemas',
  'document-schema-templates',
  'documents',
  'supertray-tokens',
  'subscriptions',
];

feathers.configure(
  createFeathersClientAdapter({
    store,
    services: {
      ...stateServices.reduce<Record<string, FeathersTabularStateAdapterService>>((acc, v) => {
        acc[v] = {
          // events: ['created', 'updated', 'patched', 'removed'],
          events: [],
          tableName: v,
          idField: 'id',
          optimistic: true,
        };
        return acc;
      }, {}),
    },
  }),
);

const apiCache = new Map<string, number>();

store.plugin({
  mount: () => {
    const disposeGetRow = store.hook('before', 'getRow', (ctx) => {
      const { table, rowId } = ctx.params;
      if (!rowId) return;
      apiQueue.enqueue(
        () => {
          if (!apiAccessToken) return null;
          const key = `getRow:${table}:${rowId}`;
          const lastCached = apiCache.get(key);
          const now = Date.now();
          if (lastCached && lastCached > now - 10000) {
            return null;
          }
          apiCache.set(key, Date.now());

          return feathers
            .service(table)
            .get(rowId, {
              headers: {
                Authorization: `Bearer ${apiAccessToken}`,
              },
            })
            .catch((e: unknown) => {
              debug.error(e);
            });
        },
        {
          name: `get:${table}:${rowId}`,
        },
      );
    });

    const disposeQueryRows = store.hook('before', 'queryRows', (ctx) => {
      const { table, query } = ctx.params;
      if (!query) return;
      const feathersQuery = toFeathersQuery(query);
      apiQueue.enqueue(
        () => {
          if (!apiAccessToken) return null;
          const key = `queryRows:${table}:${JSON.stringify(feathersQuery)}`;
          const lastCached = apiCache.get(key);
          const now = Date.now();
          if (lastCached && lastCached > now - 10000) {
            return null;
          }
          apiCache.set(key, Date.now());

          return feathers
            .service(table)
            .find({
              query: feathersQuery,
              headers: {
                Authorization: `Bearer ${apiAccessToken}`,
              },
            })
            .catch((e: unknown) => {
              debug.error(e);
            });
        },
        {
          name: `find:${table}`,
        },
      );
    });

    return () => {
      disposeGetRow();
      disposeQueryRows();
    };
  },
});

export function baseRequestAfterAuth(userId: string) {
  if (!apiAccessToken) return Promise.resolve();
  return Promise.all([
    feathers.service('users').get(userId, {
      headers: {
        Authorization: `Bearer ${apiAccessToken}`,
      },
    }),
    feathers.service('documents').find({
      query: { $limit: 10 },
      headers: {
        Authorization: `Bearer ${apiAccessToken}`,
      },
    }),
    feathers.service('document-schemas').find({
      query: { $limit: 10 },
      headers: {
        Authorization: `Bearer ${apiAccessToken}`,
      },
    }),
    feathers.service('document-schema-templates').find({
      query: { $limit: 10 },
      headers: {
        Authorization: `Bearer ${apiAccessToken}`,
      },
    }),
    feathers.service('subscriptions').find({
      query: { $limit: 10 },
      headers: {
        Authorization: `Bearer ${apiAccessToken}`,
      },
    }),
  ]);
}
