import { useMemo } from 'react';

import clsx from 'clsx';

export type TableColumnProps = {
  align?: 'left' | 'right' | 'center';
  first?: boolean;
  last?: boolean;
};

export function useTableColumn(props: TableColumnProps) {
  const { align, first, last } = props;

  const className = useMemo(() => {
    return clsx('py-3.5 px-3 text-sm', {
      'text-left': align === 'left' || !align,
      'text-right': align === 'right',
      'text-center': align === 'center',
      'pl-4 sm:pl-6': first,
      'pr-4 sm:pr-6': last,
    });
  }, [align, first, last]);

  return { className };
}
