import type { TableColumnProps } from './TableColumn';

import clsx from 'clsx';

import { useTableColumn } from './TableColumn';

export function TableHead(props: { children: React.ReactNode }) {
  return (
    <thead className="bg-gray-20">
      <tr>{props.children}</tr>
    </thead>
  );
}

export type TableHeadColumnProps = {
  children: React.ReactNode;
} & TableColumnProps;

TableHead.Column = function Column(props: TableHeadColumnProps) {
  const { children, ...tableColumnProps } = props;
  const { className } = useTableColumn(tableColumnProps);

  return (
    <th scope="col" className={clsx(className, 'font-semibold text-gray-120')}>
      {children}
    </th>
  );
};
