/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactNode } from 'react';
import { memo, useEffect } from 'react';

import { useMount, useUnmount } from '@legendapp/state/react';
import { useVerifyOneRender } from '@legendapp/state/trace';
import { useIntl } from '@tiny-intl/react';

import { debug } from '../../utils';

/* eslint-disable react/require-default-props */
type PageTitle = {
  title?: string;
  intlTitle?: string;
  intlCount?: number;
};
/* eslint-enable react/require-default-props */

export type PageProps = {
  children: ReactNode;
} & (
  | {
      title: string;
      intlTitle?: undefined;
      intlCount?: undefined;
    }
  | {
      title?: undefined;
      intlTitle: string;
      intlCount?: number;
    }
);

function MetaTitle(props: PageTitle) {
  const { t, tc } = useIntl();
  const title = !props.intlCount
    ? props.title ?? t(props.intlTitle!)
    : props.title ?? tc(props.intlTitle!, props.intlCount);

  useEffect(() => {
    if (title.slice(0, 1) !== '[') {
      document.title = `${import.meta.env.VITE_APP_TITLE_PREFIX}${title}${
        import.meta.env.VITE_APP_TITLE_SUFFIX
      }`;
    }
  }, [title]);

  return null;
}

export const Page = memo(
  (props: PageProps) => {
    useMount(() => {
      const measure = performance.measure('finish-routing', 'start-routing');
      debug.log(`Finished rendering in ${measure.duration}ms`);
    });

    useUnmount(() => {
      performance.mark('start-routing');
    });

    if (import.meta.env.PROD) {
      useVerifyOneRender('Page'); // eslint-disable-line react-hooks/rules-of-hooks
    }

    return (
      <>
        <MetaTitle title={props.title} intlTitle={props.intlTitle} intlCount={props.intlCount} />
        {props.children}
      </>
    );
  },
  (prev, next) => {
    return (
      prev.title === next.title &&
      prev.intlTitle === next.intlTitle &&
      prev.children === next.children
    );
  },
);
