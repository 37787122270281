import type { DocumentSchema } from '@supertray/shared';

import { toCamelCase } from './toCamelCase';

export function createSchemaFields(inputSchema: DocumentSchema['jsonSchema']['properties']) {
  const schema: DocumentSchema['jsonSchema']['properties'] = {};

  const createSlugLabel = (title: string, parentObject: object) => {
    let label = toCamelCase(title);
    let i = 2;
    // eslint-disable-next-line no-loop-func
    while (Object.keys(parentObject).some((key) => key === label)) {
      label = `${label}${i}`;
      i += 1;
    }
    return label;
  };

  Object.entries(inputSchema).forEach(([, value]) => {
    const label = createSlugLabel(value.title, schema);
    Object.assign(schema, {
      [label]: value,
    });

    if (
      value.type === 'array' &&
      typeof value.items !== 'string' &&
      value.items.type === 'object'
    ) {
      const newProperties: DocumentSchema['jsonSchema']['properties'] = {};

      Object.entries(value.items.properties).forEach(([, value]) => {
        newProperties[createSlugLabel(value.title, newProperties)] = value;
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      schema[label].items.properties = newProperties;
    }

    if (value.type === 'object') {
      const newProperties: DocumentSchema['jsonSchema']['properties'] = {};

      Object.entries(value.properties).forEach(([, value]) => {
        newProperties[createSlugLabel(value.title, newProperties)] = value;
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      schema[label].properties = newProperties;
    }
  });

  return schema;
}
