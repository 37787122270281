import { Outlet, useNavigate } from 'react-router-dom';

import { Trans } from '@tiny-intl/react';

import { CreateSchema } from './CreateSchema';
import { Documents } from './Documents';
import { EditSchema } from './EditSchema';
import { Schemas } from './Schemas';
import { Settings } from './settings';
import { Button, DropdownMenu, MainNavigation } from '../../components';
import { useAuth } from '../../utils';

export function Application() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <MainNavigation
        logo={<span className="inline-flex font-bold text-lg pr-3">FilesGPT</span>}
        right={
          <DropdownMenu
            align="end"
            trigger={<Button variant="tertiary" icon="hi-mini-user-circle" size="md" />}
          >
            <DropdownMenu.Item onClick={() => navigate('settings')}>
              <Trans name="settings" />
            </DropdownMenu.Item>
            <DropdownMenu.Divider />
            <DropdownMenu.Item
              icon="hi-mini-arrow-left-on-rectangle"
              onClick={() => {
                logout()
                  .then(() => navigate('/auth'))
                  .catch(() => {});
              }}
            >
              Logout
            </DropdownMenu.Item>
          </DropdownMenu>
        }
      >
        <MainNavigation.Item href="documents">
          <Trans name="document" count={2} />
        </MainNavigation.Item>
        <MainNavigation.Item href="schemas">
          <Trans name="schema" count={2} />
        </MainNavigation.Item>
      </MainNavigation>
      <Outlet />
    </>
  );
}

Application.Schemas = Schemas;
Application.Documents = Documents;
Application.Settings = Settings;
Application.CreateSchema = CreateSchema;
Application.EditSchema = EditSchema;
