import type {
  SchemaFields,
  SchemaFieldsRecord,
  SchemaSelectField,
  SchemaArrayField,
} from '@supertray/shared';

import { useEffect, useMemo, useState } from 'react';

import { Trans, useIntl } from '@tiny-intl/react';
import { FieldArray, useField } from 'formik';

import { Button, FormikField, SelectField } from '../../components';
import { wait } from '../../utils';

type FieldComponentProps<T extends SchemaFields> = {
  field: T;
  name: string;
  onChange?: (field: T) => void;
};

const UI_TYPES = ['string', 'number', 'date', 'enum', 'array', 'object'] as const;
type UiTypes = (typeof UI_TYPES)[number];

const ARRAY_UI_TYPES = ['string', 'number', 'date', 'enum', 'object'] as const;
type ArrayUiTypes = (typeof ARRAY_UI_TYPES)[number];

function SchemaEnumField(
  props: FieldComponentProps<SchemaSelectField> & {
    onRemove?: () => void;
  },
) {
  const [field] = useField<SchemaSelectField>(props.name);

  return (
    <div>
      <div className="mb-1.5 text-xs font-medium text-gray-120/80">Select Options</div>
      <FieldArray name={`${props.name}.oneOf`}>
        {({ push, remove }) => (
          <div className="space-y-4">
            {field.value.oneOf.map(($, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="space-y-4 rounded-md border border-gray-50 p-4 py-6">
                <FormikField
                  is="TextField"
                  name={`${props.name}.oneOf.${index}.title`}
                  label="Label"
                />
                <FormikField
                  is="TextField"
                  name={`${props.name}.oneOf.${index}.const`}
                  label="Value"
                />
                {field.value.oneOf.length > 1 && (
                  <Button variant="tertiary" onClick={() => remove(index)}>
                    <Trans name="removeOption" />
                  </Button>
                )}
              </div>
            ))}
            <div className="flex items-center gap-2">
              <Button onClick={() => push({ const: '', title: '' })}>
                <Trans name="addOption" />
              </Button>
              <Button onClick={props.onRemove}>
                <Trans name="removeField" />
              </Button>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}

function ArrayField(
  props: FieldComponentProps<SchemaArrayField> & {
    onRemove?: () => void;
  },
) {
  const { items } = props.field;

  const oneOf =
    typeof props.field.items !== 'string' && 'oneOf' in props.field.items
      ? props.field.items.oneOf
      : undefined;
  const format =
    typeof props.field.items !== 'string' && 'format' in props.field.items
      ? props.field.items.format
      : undefined;

  const uiType = useMemo<ArrayUiTypes>(() => {
    if (typeof items !== 'string') {
      if (items.type === 'string' && oneOf) return 'enum';
      if (items.type === 'string' && format === 'date') return 'date';
      return 'object';
    }

    if (items === 'number') return 'number';

    return 'string';
  }, [items, oneOf, format]);

  const changeArrayUiType = (next: ArrayUiTypes) => {
    if (next === 'enum') {
      props.onChange?.({
        ...props.field,
        items: {
          type: 'string',
          oneOf: [{ const: '', title: '' }],
        },
      });
    } else if (next === 'date') {
      props.onChange?.({
        ...props.field,
        items: {
          type: 'string',
          format: 'date',
        },
      });
    } else if (next === 'object') {
      props.onChange?.({
        ...props.field,
        items: {
          type: 'object',
          properties: {},
        },
      });
    } else {
      props.onChange?.({
        ...props.field,
        items: next,
      });
    }
  };

  return (
    <div>
      <div className="mb-1.5 text-xs font-medium text-gray-120/80">Array Options</div>
      <div className="space-y-4">
        <SelectField
          label="Type"
          value={uiType}
          name={`${props.name}.items`}
          onChange={(e) => {
            changeArrayUiType(e.target.value as ArrayUiTypes);
          }}
        >
          <SelectField.Option value="string">Text</SelectField.Option>
          <SelectField.Option value="number">Number</SelectField.Option>
          <SelectField.Option value="date">Date</SelectField.Option>
          <SelectField.Option value="enum">Select</SelectField.Option>
          <SelectField.Option value="object">Object</SelectField.Option>
        </SelectField>
        {uiType === 'enum' && (
          <SchemaEnumField
            name={`${props.name}.items`}
            field={props.field.items as SchemaSelectField}
            onRemove={props.onRemove}
          />
        )}

        {uiType === 'object' && (
          <div>
            <div className="mb-1.5 text-xs font-medium text-gray-120/80">Object Options</div>
            {/* eslint-disable-next-line no-use-before-define */}
            <SchemaEditor name={`${props.name}.items.properties`} disableArrays disableObjects />
          </div>
        )}
      </div>
    </div>
  );
}

export function SchemaField(
  props: FieldComponentProps<SchemaFields> & {
    id: string;
    onRemove?: () => void;
    disableArrays?: boolean;
    disableObjects?: boolean;
  },
) {
  const { t } = useIntl();

  const { type } = props.field;
  const oneOf = 'oneOf' in props.field ? props.field.oneOf : undefined;
  const format = 'format' in props.field ? props.field.format : undefined;

  const uiType = useMemo<UiTypes>(() => {
    if (type === 'string' && oneOf) return 'enum';
    if (type === 'string' && format === 'date') return 'date';
    if (type === 'number') return 'number';
    if (type === 'array') return 'array';
    if (type === 'object') return 'object';
    return 'string';
  }, [type, oneOf, format]);

  const changeUiType = (next: UiTypes) => {
    if (next === 'enum') {
      props.onChange?.({
        ...props.field,
        type: 'string',
        oneOf: [{ const: '', title: '' }],
      });
    } else if (next === 'date') {
      props.onChange?.({
        ...props.field,
        type: 'string',
        format: 'date',
      });
    } else if (next === 'number') {
      props.onChange?.({
        ...props.field,
        type: 'number',
      });
    } else if (next === 'array') {
      props.onChange?.({
        ...props.field,
        type: 'array',
        items: 'string',
      });
    } else if (next === 'object') {
      props.onChange?.({
        ...props.field,
        type: 'object',
        properties: {},
      });
    } else {
      props.onChange?.({
        ...props.field,
        type: 'string',
      });
    }
  };

  return (
    <div id={props.id} className="mb-4 space-y-4 rounded-md border border-gray-50 p-5">
      <FormikField is="TextField" required name={`${props.name}.title`} label={t('label')} />
      <FormikField
        is="TextArea"
        required
        name={`${props.name}.description`}
        label={t('description')}
      />
      <SelectField
        label="Type"
        value={uiType}
        onChange={(e) => {
          changeUiType(e.target.value as UiTypes);
        }}
      >
        <SelectField.Option value="string">Text</SelectField.Option>
        <SelectField.Option value="number">Number</SelectField.Option>
        <SelectField.Option value="date">Date</SelectField.Option>
        <SelectField.Option value="enum">Select</SelectField.Option>
        {!props.disableArrays && <SelectField.Option value="array">Array</SelectField.Option>}
        {!props.disableObjects && <SelectField.Option value="object">Object</SelectField.Option>}
      </SelectField>
      {uiType === 'enum' && (
        <SchemaEnumField
          name={props.name}
          field={props.field as SchemaSelectField}
          onRemove={props.onRemove}
        />
      )}
      {uiType === 'array' && (
        <ArrayField
          name={props.name}
          field={props.field as SchemaArrayField}
          onChange={(field) => props.onChange?.(field)}
          onRemove={props.onRemove}
        />
      )}
      {uiType === 'object' && (
        <div>
          <div className="mb-1.5 text-xs font-medium text-gray-120/80">Object Options</div>
          {/* eslint-disable-next-line no-use-before-define */}
          <SchemaEditor name={`${props.name}.properties`} disableArrays disableObjects />
        </div>
      )}
      {uiType !== 'enum' && (
        <Button variant="tertiary" onClick={props.onRemove}>
          <Trans name="removeField" />
        </Button>
      )}
    </div>
  );
}

export function SchemaEditor(props: {
  name: string;
  disableArrays?: boolean;
  disableObjects?: boolean;
}) {
  const [field, , helper] = useField<SchemaFieldsRecord>(props.name);

  const [value, setValue] = useState(field.value);

  useEffect(() => {
    if (JSON.stringify(field.value) !== JSON.stringify(value)) {
      setValue(field.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const schemaArr = useMemo(() => {
    return Object.entries(value).sort(([, a], [, b]) => a.order - b.order);
  }, [value]);

  const triggerChange = (nextVal: SchemaFieldsRecord) => {
    helper.setValue(nextVal);
    setValue(() => ({ ...nextVal }));
  };

  const addField = () => {
    const randomString = Math.random().toString(36).substring(4, 10);
    const key = `field-${randomString}`;
    const values: SchemaFieldsRecord = {
      ...field.value,
      [`field-${randomString}`]: {
        order: Object.keys(field.value).length + 1,
        title: '',
        description: '',
        type: 'string',
      },
    };
    triggerChange(values);
    wait(100).then(() => {
      const box = document.getElementById(key);
      box?.scrollIntoView();
      const el = document.querySelector<HTMLInputElement>(`#${key} input`);
      el?.focus();
    });
  };

  const patchField = (
    key: keyof SchemaFieldsRecord,
    value: SchemaFieldsRecord[string],
    trigger = true,
  ) => {
    const values: SchemaFieldsRecord = {
      ...field.value,
      [key]: value,
    };
    if (trigger) triggerChange(values);
  };

  const removeField = (key: keyof SchemaFieldsRecord) => {
    const values = field.value;
    delete values[key];
    Object.entries(values).forEach(([k, schemaField], index) => {
      values[k] = {
        ...schemaField,
        order: index + 1,
      };
    });
    triggerChange(values);
  };

  useEffect(() => {
    setValue((curr) => ({ ...curr, ...field.value }));
  }, [field.value]);

  return (
    <div>
      <div className="mb-4 space-y-4">
        {schemaArr.map(([key, value]) => (
          <SchemaField
            key={key}
            id={key}
            name={`${props.name}.${key}`}
            field={value}
            onChange={(field) => {
              patchField(key, field);
            }}
            onRemove={() => {
              removeField(key);
            }}
            disableArrays={props.disableArrays}
            disableObjects={props.disableObjects}
          />
        ))}
      </div>
      <Button size="md" onClick={addField}>
        <Trans name="addField" />
      </Button>
    </div>
  );
}
