import { Outlet } from 'react-router-dom';

import { useIntl } from '@tiny-intl/react';

import { Button } from '../../components';

/* eslint-disable import/no-default-export */
export default function AuthLayout() {
  const { change, locale } = useIntl();

  return (
    <div className="bg-gray-10 px-6">
      <div className="m-auto flex min-h-screen w-full max-w-xs items-center py-12">
        <div className="w-full">
          <Outlet />
        </div>
      </div>
      <Button
        size="sm"
        className="fixed bottom-4 right-4"
        onClick={() => {
          change(locale === 'de-DE' ? 'en-US' : 'de-DE');
        }}
      >
        {locale === 'de-DE' ? 'EN' : 'DE'}
      </Button>
    </div>
  );
}

export * from './Signin';
export * from './Signup';
export * from './ResetPassword';
export * from './ChangeEmail';
