import { mergeProps } from '../../utils';

export type UseBaseFieldProps = {
  fieldId: string;
  size?: 'sm' | 'md';
  disabled?: boolean;
  readonly?: boolean;
  error?: string | null;
  description?: string | null;
  onClick?: () => void;
  className?: string;
};

export function useBaseField(props: UseBaseFieldProps) {
  const fieldProps = mergeProps(props, {
    size: 'sm',
    disabled: false,
    readonly: false,
    error: undefined,
    description: undefined,
  });

  return fieldProps;
}
