import styles from './BaseField.module.css';

export type BaseFieldLabelProps = {
  children: string;
  forId: string;
  required?: boolean;
};

export function BaseFieldLabel({ children, required, forId }: BaseFieldLabelProps) {
  return (
    <label htmlFor={forId} className={styles.label}>
      {children}
      {required && '*'}
    </label>
  );
}
