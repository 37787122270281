import type { UseBaseFieldProps } from './useBaseField';

import { useMemo } from 'react';

import clsx from 'clsx';

import styles from './BaseField.module.css';
import { useBaseField } from './useBaseField';

function Message({
  error,
  description,
  fieldId,
  className,
}: {
  error?: string | null;
  description?: string | null;
  fieldId: string;
  className?: string;
}) {
  return (
    <>
      {error && (
        <div id={`${fieldId}-err`} className={clsx(styles.message, styles.error, className)}>
          {error}
        </div>
      )}
      {!error && description && (
        <div id={`${fieldId}-desc`} className={clsx(styles.message, styles.description, className)}>
          {description}
        </div>
      )}
    </>
  );
}

export type BaseFieldProps = UseBaseFieldProps & {
  children: React.ReactNode;
  fieldClassName?: string;
};

export function BaseField(props: BaseFieldProps) {
  const { fieldClassName, children } = props;
  const { fieldId, size, className, disabled, readonly, error, description, onClick } =
    useBaseField(props);

  const fieldStyle = useMemo(() => {
    return clsx(styles.BaseField, styles[size], fieldClassName, {
      [styles.disabled]: disabled,
      [styles.readonly]: readonly,
      [styles.hasError]: error,
    });
  }, [size, disabled, readonly, error, fieldClassName]);

  return (
    <div className={className}>
      <div className={fieldStyle} onPointerDown={onClick}>
        {children}
      </div>
      <Message error={error} description={description} fieldId={fieldId} />
    </div>
  );
}
