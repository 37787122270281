import type { UserItem } from './types';
import type { DecodedJwt } from '../utils';
import type { Application } from '@feathersjs/feathers/lib';

import { batch, computed, observable } from '@legendapp/state';

import { toFeathersError, debug, hasJwtExpired, parseJwt } from '../utils';

type StoredAuth = {
  accessToken: string | null;
  refreshToken: string | null;
  userId: string;
};

type AuthResult = {
  accessToken: string;
  authentication: {
    strategy: 'jwt';
    accessToken: string;
    refreshToken?: string;
    payload: Omit<DecodedJwt, 'val'>;
  };
  user: UserItem;
};

const errors = {
  accessTokenExpired: 'ACCESS_TOKEN_EXPIRED',
  tokenInvalid: 'TOKEN_INVALID',
  isAuthenticating: 'IS_ALREADY_AUTHENTICATING',
  netAuthFailed: 'NET_AUTHENTICATION_FAILED',
  accessTokenNotExists: 'ACCESS_TOKEN_NOT_EXISTS',
  localUserNotFound: 'LOCAL_USER_NOT_FOUND',
  reAuthFailedAfterReconnection: 'RE_AUTHENTICATION_FAILED_AFTER_RECONNECTION',
};

export function createAuthentication(props: {
  feathers: Application;
  onAuthenticated?: (result: { userId: string }) => void | Promise<void>;
  onNetAuthenticated?: (result: { userId: string }) => void | Promise<void>;
  onUnauthenticated?: () => void | Promise<void>;
  storageKeys?: {
    store: string;
    userId: string;
  };
}) {
  const storageKeys = {
    store: props.storageKeys?.store || 'auth:store',
    userId: props.storageKeys?.userId || 'auth:authenticatedUserId',
  };

  const state = observable<{
    initialized: boolean;
    authenticating: boolean;
    lastNetAuthAt: number;
    authenticatedUserId: string | null;
    store: StoredAuth[];
    connected: boolean;
    lastConnectedAt: number;
  }>({
    initialized: false,
    authenticating: false,
    lastNetAuthAt: 0,
    authenticatedUserId: null,
    store: [],
    connected: false,
    lastConnectedAt: 0,
  });

  function findUserByUserId(userId: string) {
    return computed(() => {
      const user = state.store.find((v) => v.userId === userId);
      if (user) return user;
      return undefined;
    });
  }

  function findAccessTokenByUserId(userId: string) {
    return computed(() => {
      const user = findUserByUserId(userId);
      if (user) return user.accessToken.get();
      return undefined;
    });
  }

  const authenticated = computed(() => {
    return !!state.authenticatedUserId.get();
  });

  const networkAuthenticated = computed(() => {
    return state.lastNetAuthAt.get() > state.lastConnectedAt.get();
  });

  const accessToken = computed(() => {
    const userId = state.authenticatedUserId.get();
    if (!userId) return undefined;
    return findAccessTokenByUserId(userId).get();
  });

  function persist() {
    localStorage.setItem(storageKeys.store, JSON.stringify(state.store.peek()));
    localStorage.setItem(storageKeys.userId, JSON.stringify(state.authenticatedUserId.peek()));
  }

  async function init(cb?: () => void | Promise<void>) {
    batch(() => {
      const store = localStorage.getItem(storageKeys.store);
      const userId = localStorage.getItem(storageKeys.userId);
      if (store) {
        state.store.set(JSON.parse(store));
      }
      if (userId) {
        state.authenticatedUserId.set(JSON.parse(userId));
      }
      if (!cb) state.initialized.set(true);
    });
    if (cb) {
      await cb();
      state.initialized.set(true);
    }
  }

  function saveUser(user: Partial<StoredAuth> & { userId: string }) {
    const store = state.store.peek();
    const index = store.findIndex((v) => v.userId === user.userId);
    if (index === -1) {
      store.push({
        accessToken: null,
        refreshToken: null,
        ...user,
      });
    } else {
      state.store[index].set((curr) => {
        return {
          ...curr,
          ...user,
        };
      });
    }
    persist();
  }

  async function authenticateUser(userId: string) {
    if (state.authenticatedUserId.peek() !== userId) {
      state.authenticatedUserId.set(userId);
    }
    persist();
    await props.onAuthenticated?.({
      userId,
    });
  }

  async function unauthenticateUser(userId?: string) {
    batch(() => {
      const thisUser = userId || state.authenticatedUserId.peek();
      state.authenticatedUserId.set(null);
      state.lastNetAuthAt.set(0);
      if (thisUser) {
        saveUser({
          accessToken: null,
          refreshToken: null,
          userId: thisUser,
        });
      }
    });
    await props.onUnauthenticated?.();
  }

  async function netAuthenticateRequest(accessToken: string) {
    const result: AuthResult = await props.feathers.service('authentication').create({
      strategy: 'jwt',
      accessToken,
    });
    return result;
  }

  async function netAuthenticate(accessToken: string) {
    performance.mark('start-net-authenticate');
    const result = await netAuthenticateRequest(accessToken);
    saveUser({
      accessToken: result.accessToken,
      userId: result.user.id,
    });
    state.lastNetAuthAt.set(Date.now());
    await authenticateUser(result.user.id);
    await props.onNetAuthenticated?.({
      userId: result.user.id,
    });
    const measure = performance.measure('net-authenticate', 'start-net-authenticate');
    debug.log(`Authenticated with network in ${measure.duration}ms`);
    return result;
  }

  async function authenticate(accessToken: string) {
    if (state.authenticating.peek() === true) {
      throw new Error(errors.isAuthenticating);
    }

    performance.clearMarks('start-authenticate');
    performance.mark('start-authenticate');
    state.authenticating.set(true);

    const { exp, sub: userId, val } = parseJwt(accessToken);
    if (!val) {
      state.authenticating.set(false);
      throw new Error(errors.tokenInvalid);
    }

    const expired = hasJwtExpired(exp);
    if (expired) {
      state.authenticating.set(false);
      throw new Error(errors.accessTokenExpired);
    }

    try {
      const foundIndex = state.store.findIndex((v) => v.userId === userId);
      if (foundIndex >= 0) {
        await authenticateUser(userId);
        state.authenticating.set(false);
      }

      if (state.connected.peek()) {
        await netAuthenticate(accessToken);
      } else {
        // listen to first connection and authenticate
        const dispose = state.lastConnectedAt.onChange((next) => {
          if (next > state.lastNetAuthAt.peek()) {
            netAuthenticate(accessToken).catch(async (e) => {
              const error = toFeathersError(e);
              if (error.feathers) {
                debug.log('Failed authentication after connection.');
                await unauthenticateUser(userId);
              }
            });
          }
          dispose();
        });
      }
      state.authenticating.set(false);
      const measure = performance.measure('finish-authenticate', 'start-authenticate');
      debug.log(`Finished authentication after ${measure.duration}ms`);
      performance.clearMarks('start-authenticate');
      performance.clearMeasures('finish-authenticate');
      return {
        userId,
      };
    } catch (e) {
      await unauthenticateUser(userId);
      const measure = performance.measure('finish-authenticate', 'start-authenticate');
      debug.log(`Failed authentication after ${measure.duration}ms`);
      performance.clearMarks('start-authenticate');
      performance.clearMeasures('finish-authenticate');
      throw e;
    }
  }

  function setAccessToken(next: string) {
    const { sub: userId } = parseJwt(next);
    saveUser({
      accessToken: next,
      userId,
    });
  }

  async function switchAccount(userId: string) {
    const user = findUserByUserId(userId).peek();
    if (!user) {
      throw new Error(errors.localUserNotFound);
    }
    const { accessToken } = user;
    if (!accessToken) {
      throw new Error(errors.accessTokenNotExists);
    }
    await authenticate(accessToken);
  }

  init();

  return {
    initialized: state.initialized,
    authenticating: state.authenticating,
    authenticated,
    authenticatedUserId: state.authenticatedUserId,
    authenticate,
    accessToken,
    networkAuthenticated,
    findAccessTokenByUserId,
    findUserByUserId,
    switchAccount,
    setAccessToken,
    saveUser,
    logout: unauthenticateUser,
    setConnected: (next: boolean) => {
      if (next === state.connected.peek()) return;
      state.connected.set(next);
      if (next) {
        const lastAuth = state.lastNetAuthAt.peek();
        state.lastConnectedAt.set(Date.now());
        if (lastAuth <= 0) return;
        const userId = state.authenticatedUserId.peek();
        if (!userId) return;
        const token = findAccessTokenByUserId(userId).peek();
        if (!token) return;
        netAuthenticate(token)
          .then(() => {
            debug.log('Reauthenticated after reconnection');
          })
          .catch((e) => {
            debug.error(errors.reAuthFailedAfterReconnection, e);
          });
      }
    },
  };
}
