/* eslint-disable react/no-danger */
import type { TranslateProps } from '@tiny-intl/react';

import { Trans } from '@tiny-intl/react';

export type HtmlTransProps = TranslateProps;

export function HtmlTrans(props: HtmlTransProps) {
  return <Trans {...props}>{(t) => t && <span dangerouslySetInnerHTML={{ __html: t }} />}</Trans>;
}
