import type { StringWithAutocomplete } from '../../types';
import type { MongoQuery, Subject, AnyMongoAbility } from '@casl/ability';

import { createContext, useContext } from 'react';

import { createMongoAbility, subject as buildSubject, createAliasResolver } from '@casl/ability';
import { computed, observable } from '@legendapp/state';

export type AbilityPermission = {
  action: StringWithAutocomplete<'read' | 'update' | 'patch' | 'create' | 'delete'>;
  subject: Subject | [string, Record<PropertyKey, any>];
  conditions: MongoQuery<Record<string, any>>;
  fields?: string[];
};

const DEFAULT_CONTEXT_ID = 'root';

const globalAbilities = observable<AnyMongoAbility>();

export const createAbilityContext = (p?: { id?: string; ability?: AnyMongoAbility }) => {
  const customAbilities = observable<AnyMongoAbility>();

  const props = {
    id: DEFAULT_CONTEXT_ID,
    ...p,
  };

  if (props?.ability) {
    customAbilities.set(props.ability);
  }

  const abilities = computed(() => {
    if (customAbilities.get()) return customAbilities.get();
    return globalAbilities.get();
  });

  function can(
    action: AbilityPermission['action'],
    subject: AbilityPermission['subject'],
    field?: string,
  ) {
    const comp = computed(() => {
      if (Array.isArray(subject)) {
        return (
          abilities.get()?.can(action, buildSubject(subject[0], { ...subject[1] }), field) || false
        );
      }
      return abilities.get()?.can(action, subject, field) || false;
    });
    return comp;
  }

  function cannot(
    action: AbilityPermission['action'],
    subject: AbilityPermission['subject'],
    field?: string,
  ) {
    const comp = computed(() => {
      if (Array.isArray(subject)) {
        return (
          abilities.get()?.cannot(action, buildSubject(subject[0], { ...subject[1] }), field) ||
          false
        );
      }
      return abilities.get()?.cannot(action, subject, field) || false;
    });
    return comp;
  }

  const setAbilities = (ability: AnyMongoAbility) => customAbilities.set(ability);

  return {
    [props.id]: {
      can,
      cannot,
      setAbilities,
    },
  };
};

export const AbilityContext = createContext<ReturnType<typeof createAbilityContext> | undefined>(
  undefined,
);

export function useAbility(id = DEFAULT_CONTEXT_ID) {
  const ctx = useContext(AbilityContext);

  if (!ctx) throw new Error('Needs to be wrapped inside AbilityContext.Provider');
  if (!ctx[id]) throw new Error(`No Ability Context with id ${id} found.`);

  return ctx[id];
}

const setGlobalAbilities = (ability: AnyMongoAbility) => {
  globalAbilities.set(ability);
};

export {
  setGlobalAbilities,
  createMongoAbility,
  createAliasResolver,
  AnyMongoAbility as MongoAbility,
  buildSubject,
};
