import { observable } from '@legendapp/state'; // eslint-disable-line import/no-extraneous-dependencies

export type Theme = 'system' | 'light' | 'dark';

export type CreateThemeServiceProps = {
  defaultTheme?: Theme;
  storageKey?: string;
  onChange?: (next: Theme, prev: Theme) => void;
};

export const createThemeService = (props?: CreateThemeServiceProps) => {
  const theme = observable<Theme>(props?.defaultTheme || 'system');
  const darkTheme = observable(false);

  const storageKey = () => props?.storageKey || 'theme';

  const systemIsDark = () => {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  };

  const toggleDarkClass = (on: boolean) => {
    document.querySelector('html')?.classList[on ? 'add' : 'remove']('dark');
    darkTheme.set(on);
  };

  const change = (next: Theme) => {
    const prev = theme.peek();
    if (next === prev) return;
    theme.set(next);
    if (next === 'system') {
      toggleDarkClass(systemIsDark());
    } else {
      toggleDarkClass(next === 'dark');
    }
    localStorage.setItem(storageKey(), next);
    props?.onChange?.(next, prev);
  };

  const mount = () => {
    const stored = localStorage.getItem(storageKey());
    if (stored) {
      change(stored as Theme);
    } else {
      localStorage.setItem(storageKey(), 'system');
    }
    const currTheme = theme.peek();
    toggleDarkClass(currTheme === 'dark' || (currTheme === 'system' && systemIsDark()));
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      if (theme.peek() === 'system') {
        toggleDarkClass(e.matches);
      }
    });
    window.addEventListener('storage', (ev) => {
      if (ev.key === storageKey()) {
        change(ev.newValue as Theme);
      }
    });
  };

  return {
    theme,
    setTheme: change,
    mount,
    darkTheme,
  };
};
