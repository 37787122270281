import type { ButtonProps } from '../Button';

import { useFormikContext } from 'formik';

import { Button } from '../Button';

export type FormikSubmitProps = Omit<ButtonProps, 'type' | 'loading'>;

export function FormikSubmit({ children, ...props }: FormikSubmitProps) {
  const { isSubmitting, values, setErrors, validateForm, submitForm } = useFormikContext();

  return (
    <Button
      {...props}
      icon={undefined}
      type="submit"
      loading={isSubmitting}
      onClick={(e) => {
        e.preventDefault();
        validateForm(values).then((errors) => {
          if (errors) {
            setErrors(errors);
          }
          const isValid =
            Object.keys(errors).length === 0 || Object.values(errors).every((v) => !v);
          if (isValid) submitForm();
        });
      }}
    >
      {children}
    </Button>
  );
}
