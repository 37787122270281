import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useLocationSearchParams<
  T extends Record<string, string | boolean | number | undefined>,
>(): Partial<T> {
  const { search } = useLocation();

  const keyValue = useMemo(() => {
    const values = search.replace('?', '').split('&');
    const keyValue: [string, string | boolean | number | undefined][] = values.map((v) => {
      const [key, value] = v.split('=') as [string, string];
      if (!Number.isNaN(Number(value))) {
        return [key, Number(value)];
      }
      if (value === 'true' || value === 'false') {
        return [key, value === 'true'];
      }
      return [key, decodeURIComponent(value)];
    });
    return Object.fromEntries(keyValue) as Partial<T>;
  }, [search]);

  return keyValue;
}
