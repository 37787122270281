import { useSelector } from '@legendapp/state/react';
import { Trans, useIntl } from '@tiny-intl/react';
import { Form, Formik } from 'formik';
import { toast } from 'sonner';

import { feathers, requests, store } from '../../../api';
import { Button, FormikField, FormikSubmit } from '../../../components';
import { FormLayout } from '../../../components/FormLayout';
import { Page } from '../../../features';
import { useAuth, useFormikValidation, validator } from '../../../utils';

export function Account() {
  const { t, tc } = useIntl();
  const { authenticatedUserId, accessToken } = useAuth();
  const user = useSelector(() => {
    const userId = authenticatedUserId.get();
    if (!userId) return undefined;
    return store.getRow('users', userId).get();
  });

  const personalInformationValidation = useFormikValidation({
    firstName: [validator.required(t('validation.required'))],
    lastName: [validator.required(t('validation.required'))],
  });

  return (
    <Page intlTitle="account">
      <FormLayout className="container">
        <FormLayout.Section title={t('personalInformation')}>
          {user && (
            <Formik
              initialValues={user}
              validate={personalInformationValidation.validate}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={async (values) => {
                await feathers.service('users').patch(
                  user.id,
                  {
                    firstName: values.firstName,
                    lastName: values.lastName,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${accessToken.peek()!}`,
                    },
                  },
                );
                toast.success(t('accountUpdated'));
              }}
            >
              <Form className="grid grid-cols-1 gap-4">
                <FormikField
                  {...personalInformationValidation.attr.firstName}
                  is="TextField"
                  name="firstName"
                  size="md"
                  label={t('firstName')}
                />
                <FormikField
                  {...personalInformationValidation.attr.lastName}
                  is="TextField"
                  name="lastName"
                  size="md"
                  label={t('lastName')}
                />
                <div className="inline-flex justify-self-end">
                  <FormikSubmit size="md">{t('save')}</FormikSubmit>
                </div>
              </Form>
            </Formik>
          )}
        </FormLayout.Section>
        <FormLayout.Section title={t('loginDetails')}>
          <Formik
            initialValues={{}}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async () => {
              const token = accessToken.peek();
              if (!token) return;
              await requests.account.sendEmailChangeMail(token);
              toast.success(t('mailSent'));
            }}
          >
            <Form className="mb-2">
              <div className="py-3">
                <p className="mb-2">
                  <Trans name="changeEmailDescription" />
                </p>
                <FormikSubmit>{t('changeEmail')}</FormikSubmit>
              </div>
            </Form>
          </Formik>
          <Formik
            initialValues={{}}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async () => {
              const { email } = user || {};
              if (!email) return;
              await requests.account.sendResetPasswordMail(email);
              toast.success(t('mailSent'));
            }}
          >
            <Form>
              <div className="py-3">
                <p className="mb-2">
                  <Trans name="resetPasswordDescription" />
                </p>
                <FormikSubmit>{t('resetPassword')}</FormikSubmit>
              </div>
            </Form>
          </Formik>
        </FormLayout.Section>
        <FormLayout.Section id="api-keys" title={tc('apiKey', 2)}>
          <Formik
            initialValues={{}}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async () => {
              if (!user) return;
              await feathers.service('users').patch(
                user.id,
                {
                  refreshApiKey: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken.peek()!}`,
                  },
                },
              );
              toast.success(t('apiKeyRefreshed'));
            }}
          >
            <Form>
              <div className="py-3">
                <div
                  className="mb-2"
                  onDoubleClick={() => {
                    navigator.clipboard.writeText((user && user.apiKey) || '');
                    toast.success(t('apiKeyCopied'));
                  }}
                >
                  <span>{user && `${user.apiKey.slice(0, 6)}************`}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText((user && user.apiKey) || '');
                      toast.success(t('apiKeyCopied'));
                    }}
                  >
                    <Trans name="copy" />
                  </Button>
                  <FormikSubmit variant="tertiary">
                    <Trans name="refresh" />
                  </FormikSubmit>
                </div>
              </div>
            </Form>
          </Formik>
        </FormLayout.Section>
      </FormLayout>
    </Page>
  );
}
