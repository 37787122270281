import clsx from 'clsx';

export type TableIntroProps = {
  className?: string;
  title: string;
  description?: string;
  children?: React.ReactNode;
};

export function TableIntro(props: TableIntroProps) {
  return (
    <div className={clsx('py-6 sm:flex sm:items-center sm:px-0.5', props.className)}>
      <div className="sm:flex-auto">
        <h1 className="text-lg font-semibold leading-6 text-gray-120">{props.title}</h1>
        {props.description && <p className="mt-2 text-base text-gray-110">{props.description}</p>}
      </div>
      {props.children && <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">{props.children}</div>}
    </div>
  );
}
