export const wait = (time: number): Promise<void> => {
  if (time > 0) {
    return new Promise((res) => {
      setTimeout(() => {
        res();
      }, time);
    });
  }
  return new Promise((res) => {
    res();
  });
};

export const waitUntil = (time: number[], state: () => boolean) => {
  if (!state()) {
    return time.reduce(async (previous, current) => {
      const prev = await previous;
      if (prev === false) await wait(current);
      if (state()) {
        return true;
      }
      return false;
    }, Promise.resolve(false));
  }
  return Promise.resolve(true);
};
