import type { DocumentItem } from '../../api/types';

import { Fragment, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from '@legendapp/state/react';
import { Trans, useIntl } from '@tiny-intl/react';
import { toast } from 'sonner';

import { feathers, store } from '../../api';
import { Button } from '../../components';
import { Page } from '../../features';
import { toFeathersError, useAuth } from '../../utils';

export function DocumentDetail() {
  const { t } = useIntl();
  const { documentId } = useParams<{ documentId: string }>();
  const { accessToken, authenticatedUserId } = useAuth();
  const navigate = useNavigate();

  const documentData = useSelector(() => {
    if (!documentId) return undefined;
    return store.getRow('documents', documentId).get();
  });

  const currentDocumentSchema = useSelector(() => {
    if (!documentData?.documentSchemaId) return undefined;
    return store.getRow('document-schemas', documentData.documentSchemaId).get();
  });

  const newerDocumentSchemaAvailable = useMemo(() => {
    if (!documentData || !currentDocumentSchema) return false;
    return (
      JSON.stringify(documentData.jsonSchema) !== JSON.stringify(currentDocumentSchema.jsonSchema)
    );
  }, [documentData, currentDocumentSchema]);

  const data = documentData?.data;
  const jsonProperties = documentData?.jsonSchema?.properties;
  const metadata = useMemo(() => {
    if (!data) return undefined;
    const values: {
      value: any;
      label: string;
      order: number;
      type: string;
      format?: string;
    }[] = Object.entries(data)
      .map(([key, value]) => {
        const jsonSchemaProperty = jsonProperties?.[key];
        return {
          value,
          label: jsonSchemaProperty?.title || key,
          order: jsonSchemaProperty?.order || 999,
          type: jsonSchemaProperty?.type || 'undef',
          format: jsonSchemaProperty?.format || undefined,
        };
      })
      .sort((a, b) => a.order - b.order);
    return values;
  }, [data, jsonProperties]);

  const handleCopy = () => {
    const payload = {
      id: documentData?.id,
      ocr: documentData?.ocr,
      data,
      schema: jsonProperties,
    };
    navigator.clipboard.writeText(JSON.stringify(payload, null, 2));
    toast.success(t('copiedToClipboard'));
  };

  const [supertrayTokens] = store.queryRows('supertray-tokens', {
    query: { documentId, origin: { $ne: 'tokenBilling' } },
  });
  const usedTokens = useSelector(() => {
    const usedTokens: { [key: string]: number } = {
      ocrRecognition: 0,
      classification: 0,
      dataExtraction: 0,
      assistant: 0,
      total: 0,
    };
    supertrayTokens.get().forEach((token) => {
      usedTokens[token.origin] += token.tokens;
      usedTokens.total += token.tokens;
    });
    return usedTokens;
  });

  const deleteDocument = () => {
    if (!documentData) return;
    toast(t('deleteDocument.description'), {
      duration: 30000,
      action: {
        label: t('delete'),
        onClick: () => {
          feathers
            .service('documents')
            .remove(documentData.id, {
              headers: {
                Authorization: `Bearer ${accessToken.peek()!}`,
              },
            })
            .then(() => {
              toast.success(t('deleteDocument.success'));
              navigate('../documents');
            })
            .catch((e: any) => {
              const error = toFeathersError(e);
              if (error.feathers) {
                toast.error(error.feathers.name);
              } else {
                toast.error(t('generalError'));
              }
            });
        },
      },
    });
  };

  const regenerateMetadata = () => {
    if (!documentData || !currentDocumentSchema) return;

    const payload: Partial<DocumentItem> = {
      documentSchemaId: currentDocumentSchema.id,
    };

    toast.promise(
      feathers.service('documents').patch(documentData.id, payload, {
        headers: {
          Authorization: `Bearer ${accessToken.peek()!}`,
        },
      }),
      {
        loading: `${t('documentProcessing')}...`,
        // eslint-disable-next-line react/no-unstable-nested-components
        success: (data) => {
          const userId = authenticatedUserId.get();
          store.queryRows('documents', {
            query: {
              actions: {
                classification: { enabled: true },
                dataExtraction: { enabled: true },
              },
              deleted: false,
            },
          });

          return (
            <div className="flex items-center">
              <div>
                <div>{t('documentWasProcessed', { name: data.fileName })}</div>
              </div>

              {userId && (
                <button
                  className="!ml-2"
                  data-button
                  onClick={() => {
                    navigate(`/u/${userId}/documents/${data.id as string}`);
                    toast.dismiss();
                  }}
                >
                  {t('open')}
                </button>
              )}
            </div>
          );
        },
        error: (error) => {
          if (error.message === 'CreditBalanceUsedUp') {
            return t('creditBalanceUsedUp');
          }
          if (error.message === 'SubscriptionUsageLimitExceeded') {
            return t('useageLimitExceeded');
          }
          if (error.message === 'TooManyRequests') {
            return t('tooManyDocumentProcessingRequests');
          }
          return t('generalError');
        },
      },
    );

    navigate('../documents');
  };

  return documentData ? (
    <Page title={documentData.fileName}>
      <div className="container py-6">
        <h2 className="mb-4 text-xl font-semibold">{documentData.fileName}</h2>
        <div className="grid grid-cols-1 gap-y-4">
          <div className="flex items-center justify-between gap-3">
            <Button onClick={() => handleCopy()}>Copy JSON</Button>
            <Button onClick={() => deleteDocument()}>{t('deleteDocument.title')}</Button>
          </div>
          {metadata && (
            <div className="rounded-md border border-gray-50 bg-gray-1 p-6 shadow-sm">
              <div className="mb-3 text-lg font-semibold text-gray-120">
                <Trans name="metadata" />
              </div>
              <div className="grid grid-cols-2 gap-x-3 gap-y-1">
                {metadata.map((item) => {
                  let value = '';
                  if (!Array.isArray(item.value)) {
                    if (typeof item.value === 'object') {
                      value = JSON.stringify(item.value);
                    } else {
                      value = item.value;
                    }
                  } else if (typeof item.value[0] === 'object') {
                    value = JSON.stringify(item.value);
                  } else {
                    value = item.value.join(', ');
                  }

                  return (
                    <Fragment key={item.label}>
                      <div className="font-medium text-gray-120">{item.label}</div>
                      <div className="break-all text-gray-110 ">{value}</div>
                    </Fragment>
                  );
                })}
              </div>
              {newerDocumentSchemaAvailable && (
                <div>
                  <div className="mt-3 border-t border-gray-50" />

                  <div className="mt-3">
                    <p className="select-none whitespace-pre-line text-base leading-5 text-gray-110">
                      {t('newSchemaAvalibale')}
                    </p>
                    <Button className="mt-1" onClick={() => regenerateMetadata()}>
                      {t('regenerateMetadata')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="whitespace-pre-line rounded-md border border-gray-50 bg-gray-1 p-6 shadow-sm">
            <div className="mb-3 text-lg font-semibold text-gray-120">OCR</div>
            {documentData.ocr}
          </div>

          <div className="rounded-md border border-gray-50 bg-gray-1 p-6 shadow-sm">
            <div className="mb-3 text-lg font-semibold text-gray-120">
              <Trans name="tokenUsage" />
            </div>
            <div className="grid grid-cols-2 gap-x-3 gap-y-1">
              {Object.keys(usedTokens).map((type) => (
                <Fragment key={type}>
                  <div className="font-medium text-gray-120">{t(type)}</div>
                  <div className="text-gray-110">{usedTokens[type]}</div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Page>
  ) : null;
}
