import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';

import { App } from './App';
import './index.css';
import { constants } from './utils';

import('./utils/devtools/devMode')
  .then((pack) => {
    pack.devMode(
      import.meta.env.VITE_APP_DEV_MODE === 'true' ? '20' : '1',
      constants.storageKeys.devMode,
    );
  })
  .catch(() => {});

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
