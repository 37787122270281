import type { IconNames } from '../Icon';

import { forwardRef } from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';

import styles from './DropdownMenu.module.css';
import { Icon } from '../Icon';

export type DropdownProps = {
  trigger: React.ReactNode;
  children: React.ReactNode;
  align?: DropdownMenuPrimitive.DropdownMenuContentProps['align'];
  side?: DropdownMenuPrimitive.DropdownMenuContentProps['side'];
};

export function DropdownMenu(props: DropdownProps) {
  return (
    <DropdownMenuPrimitive.Root>
      <DropdownMenuPrimitive.Trigger asChild>{props.trigger}</DropdownMenuPrimitive.Trigger>
      <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content
          className={styles.dropdownMenuContent}
          sideOffset={6}
          alignOffset={0}
          align={props.align}
        >
          {props.children}
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  );
}

DropdownMenu.Item = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
    className?: never;
    icon?: IconNames;
    right?: React.ReactNode;
  }
>(({ inset, children, ...props }, ref) => (
  <DropdownMenuPrimitive.Item {...props} ref={ref} className={styles.dropdownMenuItem}>
    <div
      className={clsx(styles.inner, {
        [styles.inset]: inset,
      })}
    >
      {props.icon && <Icon name={props.icon} size="16" className="text-gray-90" />}
      {children}
      {props.right && <div className={styles.right}>{props.right}</div>}
    </div>
  </DropdownMenuPrimitive.Item>
));
DropdownMenu.Item.displayName = DropdownMenuPrimitive.Item.displayName;

DropdownMenu.Divider = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator> & {
    className?: never;
  }
>((props, ref) => (
  <DropdownMenuPrimitive.Separator {...props} ref={ref} className={styles.dropdownMenuDivider} />
));
DropdownMenu.Divider.displayName = DropdownMenuPrimitive.Separator.displayName;
