import type { createAuthentication } from '../../api/auth';

import { createContext, useContext } from 'react';

export const AuthContext = createContext<ReturnType<typeof createAuthentication> | undefined>(
  undefined,
);

export const useAuth = () => {
  const ctx = useContext(AuthContext);

  if (!ctx) throw new Error('Needs to be wrapped inside AuthContext.Provider');

  return ctx;
};
