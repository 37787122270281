import type { LoaderFunctionArgs } from 'react-router-dom';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';

import { useSelector } from '@legendapp/state/react';

import { authenticator, requests } from './api';
import { Application } from './routes/application';
import AuthLayout, { ChangeEmail, Signin, Signup } from './routes/authentication';
import { ResetPassword } from './routes/authentication/ResetPassword';
import { useAuth, debug } from './utils';

const publicRoute = () => {
  const userId = authenticator.authenticatedUserId.peek();
  if (userId) {
    window.location.replace(`/u/${userId}/documents`);
    return null;
  }
  return null;
};

const privateRoute = async ({ params }: LoaderFunctionArgs) => {
  const { userId } = params;
  if (!userId) {
    window.location.replace('/auth');
    return null;
  }
  if (
    userId === authenticator.authenticatedUserId.peek() &&
    authenticator.networkAuthenticated.peek()
  ) {
    // allow access
    return null;
  }
  try {
    await authenticator.switchAccount(userId);
  } catch (e) {
    debug.error(e);
    window.location.replace('/auth');
    return null;
  }
  return null;
};

const unkownRoute = () => {
  const userId = authenticator.authenticatedUserId.peek();
  if (userId) {
    window.location.replace(`/u/${userId}/documents`);
  } else {
    window.location.replace('/auth');
  }
  return null;
};

const redirectRoute = (route: string) => {
  const userId = authenticator.authenticatedUserId.peek();
  if (userId) {
    window.location.replace(`/u/${userId}/${route}`);
  } else {
    window.location.replace('/auth');
  }
  return null;
};

const router = createBrowserRouter([
  {
    path: '*',
    loader: unkownRoute,
    element: <div />,
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        loader: () => publicRoute(),
        element: <Signin />,
      },
      {
        path: 'signup',
        loader: () => publicRoute(),
        element: <Signup />,
      },
      {
        path: 'verify',
        loader: async ({ request }) => {
          publicRoute();
          const url = new URL(request.url);
          const token = url.searchParams.get('token');
          const email = url.searchParams.get('email');

          if (!token) {
            window.location.replace('/auth');
            return null;
          }

          const emailParam = email ? `&email=${email}` : '';

          try {
            await requests.account.verifyEmail(token);
            window.location.replace(`/auth?verified=true${emailParam}`);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (e: any) {
            if (e.message === 'AlreadyVerified') {
              window.location.replace(`/auth?verified=true${emailParam}`);
            } else {
              window.location.replace(`/auth?verified=false${emailParam}`);
            }
          }

          return null;
        },
        element: <div />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'change-email',
        element: <ChangeEmail />,
      },
    ],
  },
  {
    path: 'settings',
    loader: () => redirectRoute('settings'),
    element: <div />,
  },
  {
    path: 'schemas',
    loader: () => redirectRoute('schemas'),
    element: <div />,
  },
  {
    path: '/u/:userId',
    loader: (args) => privateRoute(args),
    element: <Application />,
    children: [
      {
        path: '',
        loader: () => {
          return redirect('documents');
        },
      },
      {
        path: 'documents',
        element: <Application.Documents />,
      },
      {
        path: 'documents/:documentId',
        element: <Application.Documents.Document />,
      },
      {
        path: 'schemas',
        element: <Application.Schemas />,
      },
      {
        path: 'schemas/edit/:schemaId',
        element: <Application.EditSchema />,
      },
      {
        path: 'schemas/create/:templateId?',
        element: <Application.CreateSchema />,
      },
      {
        path: 'settings',
        element: <Application.Settings />,
        children: [
          {
            path: '',
            loader: () => {
              return redirect('account');
            },
          },
          {
            path: 'account',
            element: <Application.Settings.Account />,
          },
          {
            path: 'billing',
            element: <Application.Settings.Billing />,
          },
        ],
      },
    ],
  },
]);

export function AppRouter() {
  const { initialized } = useAuth();
  const isReady = useSelector(() => initialized.get() || null);

  return isReady && <RouterProvider router={router} />;
}
