import { useId } from 'react';

import { mergeProps } from '../../utils';

export type FormLayoutSectionProps = {
  title: React.ReactNode;
  description?: string;
  children: React.ReactNode;
  id?: string;
};

export function FormLayoutSection(props: FormLayoutSectionProps) {
  const { id } = mergeProps(props, {
    id: useId(),
  });

  return (
    <fieldset
      id={id}
      aria-labelledby={`label${id}`}
      className="py-4 md:grid md:grid-cols-3 md:gap-6 lg:py-6"
    >
      <div className="md:col-span-1">
        <div className="">
          <legend
            id={`label${id}`}
            className="select-none text-lg font-semibold leading-6 text-gray-120"
          >
            {props.title}
          </legend>
          {props.description && (
            <p className="mt-1.5 select-none whitespace-pre-line text-base leading-5 text-gray-110">
              {props.description}
            </p>
          )}
        </div>
      </div>
      <div className="mt-5 select-none border-gray-50 md:col-span-2 md:mt-0 md:border-l md:pl-6 lg:pl-8">
        {props.children}
      </div>
    </fieldset>
  );
}
