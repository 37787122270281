import type { DocumentSchema } from '@supertray/shared';

import { useNavigate, useParams } from 'react-router-dom';

import { useComputed, useSelector } from '@legendapp/state/react';
import { Trans, useIntl } from '@tiny-intl/react';
import { Form, Formik } from 'formik';
import { toast } from 'sonner';

import { feathers, store } from '../../api';
import { Button, FormikField, FormikSubmit, SelectField } from '../../components';
import { FormLayout } from '../../components/FormLayout';
import { SchemaEditor } from '../../features';
import {
  createSchemaFields,
  toFeathersError,
  useAuth,
  useFormikValidation,
  validator,
} from '../../utils';

type FormValues = Pick<DocumentSchema, 'name' | 'description'> & {
  schema: DocumentSchema['jsonSchema']['properties'];
};
export function EditSchema() {
  const { t } = useIntl();
  const { schemaId } = useParams<{ schemaId: string }>();
  const { accessToken } = useAuth();
  const navigate = useNavigate();

  const [documentSchemaTemplates] = store.queryRows('document-schema-templates', {});
  const showDocumentSchemaTemplateSelect = useComputed(
    () => documentSchemaTemplates.get().length > 0,
  );

  const generalValidation = useFormikValidation<FormValues>({
    name: [validator.required(t('validation.required'))],
    description: [validator.required(t('validation.required'))],
    schema: [],
  });

  const deleteSchema = () => {
    if (!schemaId) return;
    toast(t('deleteSchema.description'), {
      duration: 30000,
      action: {
        label: t('delete'),
        onClick: () => {
          feathers
            .service('document-schemas')
            .remove(schemaId, {
              headers: {
                Authorization: `Bearer ${accessToken.peek()!}`,
              },
            })
            .then(() => {
              toast.success(t('deleteSchema.success'));
              navigate('../schemas');
            })
            .catch((e: any) => {
              const error = toFeathersError(e);
              if (error.feathers) {
                toast.error(error.feathers.name);
              } else {
                toast.error(t('generalError'));
              }
            });
        },
      },
    });
  };

  const schema = useSelector(() => {
    if (!schemaId) return undefined;
    return store.getRow('document-schemas', schemaId).get();
  });

  return (
    <>
      <div className="sticky top-12 z-[2] border-b border-gray-60 bg-gray-20/75 backdrop-blur-md">
        <div className="container">
          <div className="flex items-center justify-between">
            <h1 className="py-6 text-xl font-bold">
              <Trans name="editSchema.title" />
            </h1>
            <div>
              <Button onClick={() => deleteSchema()}>
                <Trans name="deleteSchema.title" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {schema && (
          <Formik<FormValues>
            initialValues={{
              name: schema.name,
              description: schema.description,
              schema: schema.jsonSchema.properties,
            }}
            validate={generalValidation.validate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (values) => {
              const updatedSchema = createSchemaFields(values.schema);

              if (Object.keys(updatedSchema).length === 0) {
                toast.error(t('minSchemaFields'));
                return;
              }

              const payload: Partial<DocumentSchema> = {
                name: values.name,
                description: values.description || '',
                jsonSchema: {
                  type: 'object',
                  properties: updatedSchema,
                },
              };

              try {
                await feathers.service('document-schemas').patch(schema.id, payload, {
                  headers: {
                    Authorization: `Bearer ${accessToken.peek()!}`,
                  },
                });
                toast.success(t('editSchema.success'));
                navigate('../schemas');
              } catch (e) {
                const error = toFeathersError(e);
                if (error.feathers) {
                  if (error.feathers.name === 'MaxTokenLimit') {
                    toast.error(t('schemaMaxTokenExceeded'));
                  } else {
                    toast.error(error.feathers.name);
                  }
                } else {
                  toast.error(t('generalError'));
                }
              }
            }}
          >
            <Form>
              <FormLayout>
                {showDocumentSchemaTemplateSelect && schema.documentSchemaTemplateId && (
                  <FormLayout.Section title={t('schemaTemplate.title')}>
                    <div className="py-3">
                      <p className="mb-2">
                        <Trans name="schemaTemplate.select" />
                      </p>
                      <SelectField
                        disabled
                        label={t('schemaTemplate.title')}
                        value={schema.documentSchemaTemplateId}
                      >
                        {documentSchemaTemplates.get().map((schema) => (
                          <SelectField.Option key={schema.id} value={schema.id}>
                            {schema.name}
                          </SelectField.Option>
                        ))}
                      </SelectField>
                    </div>
                  </FormLayout.Section>
                )}
                <FormLayout.Section
                  title={t('generalInformations')}
                  description={t('createSchema.generalDescription')}
                >
                  <div className="space-y-4">
                    <FormikField
                      {...generalValidation.attr.name}
                      is="TextField"
                      name="name"
                      label={t('name')}
                    />
                    <FormikField
                      {...generalValidation.attr.description}
                      is="TextArea"
                      name="description"
                      label={t('description')}
                      rows={4}
                    />
                  </div>
                </FormLayout.Section>
                <FormLayout.Section title={t('schema')}>
                  <SchemaEditor name="schema" />
                </FormLayout.Section>
              </FormLayout>
              <div className="sticky bottom-0 -mx-4 flex justify-end border-t border-gray-50 bg-gray-1/75 p-4 backdrop-blur-md md:-mx-6 md:px-6 lg:-mx-8 lg:px-8">
                <FormikSubmit variant="primary" size="md">
                  {t('save')}
                </FormikSubmit>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </>
  );
}
