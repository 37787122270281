import clsx from 'clsx';

import { FormLayoutSection } from './FormLayoutSection';

export type FormLayoutSectionProps = {
  children: React.ReactNode;
  className?: string;
};

export function FormLayout(props: FormLayoutSectionProps) {
  return <div className={clsx('divide-y divide-gray-50', props.className)}>{props.children}</div>;
}

FormLayout.Section = FormLayoutSection;
