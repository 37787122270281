import type { IconNames } from '../../components';

import { NavLink } from 'react-router-dom';

import { For, Show, useComputed } from '@legendapp/state/react';
import { Trans } from '@tiny-intl/react';

import { store } from '../../api';
import { Icon } from '../../components';

export function SchemaTemplates() {
  const [documentSchemaTemplates] = store.queryRows('document-schema-templates', {});
  const showDocumentSchemaTemplates = useComputed(() => documentSchemaTemplates.get().length > 0);

  return (
    <div className="mx-auto max-w-lg select-none">
      <h2 className="text-lg font-semibold leading-6 text-gray-120">
        <Trans name="schemaTemplates.title" />
      </h2>
      <p className="mt-1 text-sm text-gray-110">
        <Trans name="schemaTemplates.description" />
      </p>
      <Show if={showDocumentSchemaTemplates}>
        <ul className="mt-6 divide-y divide-gray-50 border-y border-gray-50">
          <For each={documentSchemaTemplates}>
            {(documentSchemaTemplate) => (
              <li key={documentSchemaTemplate.id.get()}>
                <NavLink
                  to={`./create/${documentSchemaTemplate.id.get()}`}
                  className="group relative flex w-full items-start space-x-3 py-4 text-left"
                >
                  <div
                    aria-hidden
                    className="pointer-events-none absolute inset-0 z-0 -mx-3 rounded-md transition group-hover:bg-gray-30 group-focus-visible:bg-gray-30 group-active:bg-gray-40"
                  />
                  <div className="relative shrink-0">
                    <span
                      className="inline-flex h-10 w-10 items-center justify-center rounded-lg"
                      style={documentSchemaTemplate.iconStyle.get()}
                    >
                      <Icon
                        name={documentSchemaTemplate.icon.get() as IconNames}
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="relative min-w-0 flex-1">
                    <div className="font-medium text-gray-120">{documentSchemaTemplate.name}</div>
                    <p className="text-gray-110">{documentSchemaTemplate.description || ''}</p>
                  </div>
                  <div className="relative shrink-0 self-center">
                    <Icon
                      name="hi-mini-chevron-right"
                      className="h-5 w-5 text-gray-90 group-hover:text-gray-110"
                      aria-hidden="true"
                    />
                  </div>
                </NavLink>
              </li>
            )}
          </For>
        </ul>
      </Show>

      <div className="mt-6 flex">
        <NavLink
          to="create"
          className="rounded-md font-medium text-accent-100 transition hover:text-accent-120 focus-visible:ring focus-visible:ring-accent-90/40"
        >
          <Trans name="startWithEmptySchema" />
          <span aria-hidden="true"> &rarr;</span>
        </NavLink>
      </div>
    </div>
  );
}
