import { useMemo } from 'react';

export type ValidateAttributes = {
  [k: Exclude<string, 'onValidate'>]:
    | string
    | boolean
    | number
    | undefined
    | ((...args: any[]) => any);
};

export type ValidateFn<T = any> = {
  validate: (value: T) => string | undefined;
  attributes?: ValidateAttributes;
};

export function createFieldValidation<T extends Record<string, unknown>>(validator: {
  [key in keyof T]: ValidateFn<T[key]>[];
}) {
  const fieldAttributes = () => {
    const attributes: Partial<{ [key in keyof T]: ValidateAttributes }> = {};
    Object.keys(validator).forEach((key: keyof T) => {
      attributes[key] = {};
      for (const validate of validator[key]) {
        attributes[key] = {
          ...attributes[key],
          ...validate.attributes,
          onValidate: (value: T[keyof T]) => validate.validate(value),
        };
      }
    });
    return attributes as { [key in keyof T]: ValidateAttributes };
  };

  const validate = (values: T) => {
    const errors: Partial<{ [key in keyof T]: string | undefined }> = {};
    Object.keys(validator).forEach((key: keyof T) => {
      const value = values[key];
      for (const validate of validator[key]) {
        const error = validate.validate(value);
        if (error) {
          errors[key] = error;
          break;
        }
      }
    });
    return errors;
  };

  return {
    validate,
    attr: fieldAttributes(),
  };
}

/**
 * @description This is a hook to use with Formik.
 * Just add validate={validation.validate} to your Formik.
 * You have to set validateOnBlur={true} and or validateOnChange={true} on your Formik.
 * Use {...validation.attr[fieldName]} to get html attributes for your form fields.
 */
export function useFormikValidation<T extends Record<string, unknown>>(validator: {
  [key in keyof T]: ValidateFn[];
}) {
  const validation = useMemo(() => createFieldValidation(validator), [validator]);
  return validation;
}

function required<T = any>(error: string): ValidateFn<T> {
  return {
    validate: (value: T) => {
      if (typeof value === 'string') {
        return value.length ? undefined : error;
      }
      if (typeof value === 'boolean') {
        return value === true ? undefined : error;
      }
      return value ? undefined : error;
    },
    attributes: {
      required: true,
    },
  };
}

function minLength<T extends string | null | false | undefined>(
  min: number,
  error: string,
): ValidateFn<T> {
  return {
    validate: (value: T) => (value && value.length >= min ? undefined : error),
    attributes: {
      type: 'text',
      minLength: min,
    },
  };
}

function maxLength<T extends string | null | false | undefined>(
  max: number,
  error: string,
): ValidateFn<T> {
  return {
    validate: (value: T) => (value && value.length <= max ? undefined : error),
    attributes: {
      type: 'text',
      maxLength: max,
    },
  };
}

function email<T extends string | null | undefined>(error: string): ValidateFn<T> {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return {
    validate: (value: T) => (value && emailRegex.test(value) ? undefined : error),
    attributes: {
      type: 'email',
    },
  };
}

export const validator = {
  required,
  minLength,
  maxLength,
  email,
};
