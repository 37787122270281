import { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { For, Show, useComputed, useObservable } from '@legendapp/state/react';
import { Trans, useIntl } from '@tiny-intl/react';

import { store } from '../../api';
import { Button, Table } from '../../components';
import { Page, SchemaTemplates } from '../../features';

export function SchemaRows() {
  const { t } = useIntl();
  const navigate = useNavigate();
  const [data] = store.queryRows('document-schemas', {});
  const state = useObservable({
    loaded: false,
  });
  const showRows = useComputed(() => data.get().length > 0 && state.loaded.get());
  const showEmpty = useComputed(() => data.get().length === 0 && state.loaded.get());

  useEffect(() => {
    const timeout = setTimeout(() => {
      state.loaded.set(true);
    }, 100);
    return () => clearTimeout(timeout);
  }, [state]);

  return (
    <>
      <Show if={showEmpty}>
        {() => (
          <div className="py-8">
            <SchemaTemplates />
          </div>
        )}
      </Show>
      <Show if={showRows}>
        {() => (
          <>
            <Table.Intro title={t('manageSchemas')} className="mt-2">
              <Button variant="primary" onClick={() => navigate('create')}>
                <Trans name="createSchema.title" />
              </Button>
            </Table.Intro>
            <Table.Card>
              <Table>
                <Table.Head>
                  <Table.Head.Column first>
                    <Trans name="name" />
                  </Table.Head.Column>
                  <Table.Head.Column>ID</Table.Head.Column>
                  <Table.Head.Column align="right">
                    <span className="sr-only">
                      <Trans name="actions" />
                    </span>
                  </Table.Head.Column>
                </Table.Head>
                <Table.Body>
                  <For each={data} optimized>
                    {(row) => (
                      <Table.Row key={row.id.get()} onClick={() => {}}>
                        <Table.Row.Column first>{row.name.get()}</Table.Row.Column>
                        <Table.Row.Column>
                          <span className="text-mono text-xs text-gray-110">{row.id.get()}</span>
                        </Table.Row.Column>
                        <Table.Row.Column align="right" last>
                          <NavLink
                            to={`./edit/${row.id.get()}`}
                            className="select-none font-medium text-accent-100 hover:text-accent-110"
                          >
                            <Trans name="edit" />
                            <span className="sr-only">, {row.name.get()}</span>
                          </NavLink>
                        </Table.Row.Column>
                      </Table.Row>
                    )}
                  </For>
                </Table.Body>
              </Table>
            </Table.Card>
          </>
        )}
      </Show>
    </>
  );
}

export function Schemas() {
  return (
    <Page intlTitle="schema" intlCount={10}>
      <div className="container">
        <SchemaRows />
      </div>
    </Page>
  );
}
