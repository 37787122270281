import { useNavigate } from 'react-router-dom';

import { Trans, useIntl } from '@tiny-intl/react';
import { Form, Formik } from 'formik';

import { requests } from '../../api';
import { Button, FormikField, FormikSubmit } from '../../components';
import { Page } from '../../features';
import { useFormikValidation, useLocationSearchParams, validator } from '../../utils';

export function ChangeEmail() {
  const navigate = useNavigate();
  const { t } = useIntl();

  const { token } = useLocationSearchParams<{
    token: string;
  }>();

  const { validate, attr } = useFormikValidation({
    email: [validator.required(t('validation.required')), validator.email(t('validation.email'))],
  });

  return (
    <Page title={t('changeEmail')}>
      <h1 className="mb-6 select-none text-2xl font-bold">
        FilesGPT — <Trans name="changeEmail" />
      </h1>

      <Formik
        initialValues={{
          email: '',
        }}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setStatus, setErrors }) => {
          try {
            await requests.account.changeEmail({
              token: token || '',
              newEmail: values.email,
            });
            window.location.replace(`/auth?changedEmail=true&email=${values.email}`);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (err: any) {
            if (err.message) {
              if (err.message === 'EmailAlreadyUsed') {
                setErrors({ email: t('validation.emailAlreadyUsed') });
              } else {
                setStatus(err.message);
              }
            }
          }
        }}
      >
        {({ status }) => (
          <Form className="flex flex-col gap-4">
            <FormikField
              {...attr.email}
              is="TextField"
              name="email"
              label={t('newEmail')}
              size="md"
              autoComplete="username"
            />
            {status && (
              <div className="mt-1 text-base leading-4 text-red-100">
                {(() => {
                  switch (status) {
                    case 'TokenExpired':
                      return <div>{t('linkExpired')}</div>;
                    default:
                      return <div>{t('generalError')}</div>;
                  }
                })()}
              </div>
            )}
            <FormikSubmit variant="primary" size="md">
              <Trans name="changeEmail" />
            </FormikSubmit>
            <hr className="flex-1" />
            <Button variant="secondary" size="md" onClick={() => navigate('../')}>
              <Trans name="signin" />
            </Button>
          </Form>
        )}
      </Formik>
    </Page>
  );
}
