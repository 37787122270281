import clsx from 'clsx';

import { TableHead } from './TableHead';
import { TableIntro } from './TableIntro';
import { TableRow } from './TableRow';

export type TableProps = {
  children: React.ReactNode;
  className?: string;
};

export function Table(props: TableProps) {
  return (
    <div className="relative">
      <table className={clsx('min-w-full divide-y divide-gray-60', props.className)}>
        {props.children}
      </table>
    </div>
  );
}

function TableBody(props: { children: React.ReactNode }) {
  return <tbody className="divide-y divide-gray-50 dark:bg-gray-40">{props.children}</tbody>;
}

function TableCard(props: { children: React.ReactNode; className?: string }) {
  return (
    <div className={clsx('flow-root', props.className)}>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export type TableEmptyStateProps = {
  children: React.ReactNode;
  colSpan: number;
};

function TableEmptyState({ colSpan, children }: TableEmptyStateProps) {
  return (
    <tr>
      <td colSpan={colSpan} className="py-8">
        {children}
      </td>
    </tr>
  );
}

Table.Card = TableCard;
Table.Body = TableBody;
Table.Head = TableHead;
Table.Intro = TableIntro;
Table.Row = TableRow;
Table.EmptyState = TableEmptyState;
