import { detectTouch } from './detectTouch';

export type OperatingSystemName = 'windows' | 'macos' | 'linux' | 'ios' | 'android' | 'undef';

export const getOperatingSystem = (): {
  systemName: OperatingSystemName;
  modKey: 'ctrlKey' | 'metaKey';
} => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
  const windowsPlatforms = /(win32|win64|windows|wince)/i;
  const iosPlatforms = /(iphone|ipad|ipod)/i;
  let os: ['windows' | 'macos' | 'linux' | 'ios' | 'android' | 'undef', 'ctrlKey' | 'metaKey'] = [
    'undef',
    'ctrlKey',
  ];

  if (macosPlatforms.test(userAgent)) {
    os = ['macos', 'metaKey'];
  } else if (iosPlatforms.test(userAgent)) {
    os = ['ios', 'metaKey'];
  } else if (windowsPlatforms.test(userAgent)) {
    os = ['windows', 'ctrlKey'];
  } else if (/android/.test(userAgent)) {
    os = ['android', 'ctrlKey'];
  } else if (!os && /linux/.test(userAgent)) {
    os = ['linux', 'ctrlKey'];
  }

  return {
    systemName: os[0],
    modKey: os[1],
  };
};

export const initUi = () => {
  const { systemName, modKey } = getOperatingSystem();
  window.$$supertray = window.$$supertray || {};
  window.$$supertray.operatingSystem = systemName;
  window.$$supertray.modKey = modKey;
  const el = document.getElementsByTagName('html')[0];

  el.classList.add(`os-${systemName as string}`);
  el.classList.remove('touch');

  if (detectTouch()) {
    el.classList.add('touch');
  }

  if (systemName === 'ios') {
    const meta = document.querySelector('meta[name="viewport"]');
    meta?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
  }

  el.style.setProperty('--device-pixel-ratio', window.devicePixelRatio.toString());
};
