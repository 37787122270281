import { useNavigate } from 'react-router-dom';

import { Trans, useIntl } from '@tiny-intl/react';
import { Form, Formik } from 'formik';

import { requests } from '../../api';
import { Button, FormikField, FormikSubmit } from '../../components';
import { Page } from '../../features';
import { useAuth, useFormikValidation, useLocationSearchParams, validator } from '../../utils';

export function ResetPassword() {
  const navigate = useNavigate();
  const { t } = useIntl();
  const auth = useAuth();

  const { token, email } = useLocationSearchParams<{
    token: string;
    email: string;
  }>();

  const requestPasswordResetValidation = useFormikValidation({
    email: [validator.required(t('validation.required')), validator.email(t('validation.email'))],
  });

  const passwordResetValidation = useFormikValidation({
    password: [validator.required(t('validation.required'))],
  });

  return (
    <Page title={t('resetPassword')}>
      <h1 className="mb-6 select-none text-2xl font-bold">
        FilesGPT — <Trans name="resetPassword" />
      </h1>
      {!token ? (
        <Formik
          initialValues={{
            email: '',
          }}
          validate={requestPasswordResetValidation.validate}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values) => {
            await requests.account.sendResetPasswordMail(values.email);
            window.location.replace(`/auth?requestedPasswordReset=true`);
          }}
        >
          <Form className="flex flex-col gap-4">
            <FormikField
              {...requestPasswordResetValidation.attr.email}
              is="TextField"
              name="email"
              label={t('email')}
              size="md"
              autoComplete="username"
            />
            <FormikSubmit variant="primary" size="md">
              <Trans name="resetPassword" />
            </FormikSubmit>
            <div className="flex items-center">
              <hr className="flex-1" />
              <span className="px-4 text-gray-90">{t('rememberPassword')}</span>
              <hr className="flex-1" />
            </div>
            <Button variant="secondary" size="md" onClick={() => navigate('../')}>
              <Trans name="signin" />
            </Button>
          </Form>
        </Formik>
      ) : (
        <Formik
          initialValues={{
            password: '',
          }}
          validate={passwordResetValidation.validate}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values, { setStatus }) => {
            try {
              await requests.account.resetPassword({ newPassword: values.password, token });
              if (auth.authenticated) {
                await auth.logout();
              }
              window.location.replace(`/auth?passwordReset=true${email ? `&email=${email}` : ''}`);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
              if (err.message) {
                setStatus(err.message);
              }
            }
          }}
        >
          {({ status }) => (
            <Form className="flex flex-col gap-4">
              <FormikField
                {...passwordResetValidation.attr.password}
                is="TextField"
                name="password"
                type="password"
                label={t('newPassword')}
                autoComplete="current-password"
                size="md"
              />
              {status && (
                <div className="mt-1 text-base leading-4 text-red-100">
                  {(() => {
                    switch (status) {
                      case 'TokenExpired':
                        return <div>{t('linkExpired')}</div>;
                      default:
                        return <div>{t('generalError')}</div>;
                    }
                  })()}
                </div>
              )}
              <FormikSubmit variant="primary" size="md">
                <Trans name="resetPassword" />
              </FormikSubmit>
              <div className="flex items-center">
                <hr className="flex-1" />
                <span className="px-4 text-gray-90">{t('rememberPassword')}</span>
                <hr className="flex-1" />
              </div>
              <Button variant="secondary" size="md" onClick={() => navigate('../')}>
                <Trans name="signin" />
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Page>
  );
}
