type FeathersErrorResponse = {
  name: string;
  code: number;
  className?: string;
  message?: string;
};

export const toFeathersError = (
  e: unknown,
): {
  feathers?: FeathersErrorResponse;
  error?: unknown;
} => {
  if (typeof e === 'object') {
    return { feathers: { ...e } as FeathersErrorResponse };
  }
  return { error: e };
};
