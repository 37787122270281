/* eslint-disable react/jsx-props-no-spreading */
import type { BaseFieldProps } from './BaseField';
import type { Observable } from '@legendapp/state';

import { memo, useEffect, useId, useMemo, useRef } from 'react';
import type { CurrencyInputProps } from 'react-currency-input-field';
import CurrencyInput from 'react-currency-input-field';

import { observer, useObservable, useObserve } from '@legendapp/state/react';

import { BaseField } from './BaseField';
import { BaseFieldLabel } from './BaseFieldLabel';
import { mergeProps } from '../../utils';

export type FormControlFieldProps<T = any> = {
  label?: string | null;
  placeholder?: string | null;
  name?: string;
  value?: T;
  value$?: Observable<T>;
};

export type CurrencyFieldProps = Omit<BaseFieldProps, 'children' | 'fieldId'> &
  FormControlFieldProps<number | null> &
  Omit<CurrencyInputProps, 'size' | 'placeholder' | 'ref'>;

export const CurrencyField = observer((props: CurrencyFieldProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const {
    size,
    id,
    label,
    required,
    type,
    placeholder,
    disabled,
    readonly,
    value$,
    error,
    description,
    className,
    ...htmlInputProps
  } = mergeProps(props, {
    id: useId(),
    type: 'text',
    decimalsLimit: 2,
    fixedDecimalLength: 2,
    decimalScale: 2,
    disableAbbreviations: true,
    suffix: '€',
    readonly: false,
    disabled: false,
    onChange: undefined,
  });

  const errorMessage = useMemo(() => {
    return error ? `${id}-err` : undefined;
  }, [error, id]);
  const descriptedBy = useMemo(() => {
    return description ? `${id}-desc` : undefined;
  }, [description, id]);
  const fieldPlaceholder = useMemo(() => {
    if (placeholder && !label && required) return `${placeholder}*`;
    return placeholder || undefined;
  }, [placeholder, label, required]);
  const valueObs = useObservable(value$?.peek() || props.value || null);

  useObserve(() => {
    const nextVal = value$?.get();
    if (nextVal !== valueObs.peek()) {
      valueObs.set(nextVal || null);
      if (ref.current) {
        ref.current.value = nextVal?.toString() || '';
      }
    }
  });
  useEffect(() => {
    if (ref.current) {
      ref.current.value = valueObs.get()?.toString() || '';
    }
  }, [ref, valueObs]);

  return (
    <BaseField
      fieldId={id}
      disabled={disabled}
      readonly={readonly}
      size={size}
      error={error}
      description={description}
      className={className}
    >
      {label && (
        <BaseFieldLabel forId={id} required={required}>
          {label}
        </BaseFieldLabel>
      )}
      <CurrencyInput
        {...htmlInputProps}
        ref={ref}
        id={id}
        type={type}
        placeholder={fieldPlaceholder}
        required={required}
        disabled={disabled}
        readOnly={readonly}
        aria-invalid={!!errorMessage}
        aria-errormessage={errorMessage}
        aria-describedby={descriptedBy}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            e.currentTarget.blur();
          }
          props.onKeyDown?.(e);
        }}
        onInvalid={(e) => {
          e.preventDefault();
        }}
      />
    </BaseField>
  );
});

export const OptimizedCurrencyField = memo(CurrencyField, (prevProps, nextProps) => {
  return (
    prevProps.id === nextProps.id &&
    prevProps.label === nextProps.label &&
    prevProps.required === nextProps.required &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.autoComplete === nextProps.autoComplete &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.readonly === nextProps.readonly &&
    prevProps.error === nextProps.error &&
    prevProps.description === nextProps.description &&
    prevProps.size === nextProps.size
  );
});
