import clsx from 'clsx';

import styles from './SubNavigation.module.css';
import { SubNavigationItem } from './SubNavigationItem';

export type SubNavigationProps = {
  children: React.ReactNode;
};

export function SubNavigation(props: SubNavigationProps) {
  return (
    <div
      id="sub-nav"
      role="navigation"
      className={clsx(
        'sticky top-12 select-none bg-gray-1/75 backdrop-blur-md',
        styles.subNavigation,
      )}
    >
      <div className="container">
        <ul className="flex items-center gap-2">{props.children}</ul>
      </div>
    </div>
  );
}

SubNavigation.Item = SubNavigationItem;
