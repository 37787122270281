import type { I18nSupportedLocales } from './locales';

import { useEffect, useState } from 'react';

import { match } from '@formatjs/intl-localematcher';
import { useMount, useSelector } from '@legendapp/state/react';
import { createTinyIntl } from '@tiny-intl/core';
import { TinyIntlContext, useIntl } from '@tiny-intl/react';
import { Toaster } from 'sonner';

import { authenticator, store } from './api';
import { supportedLocales, load } from './locales';
import { AppRouter } from './Router';
import { AbilityContext, createAbilityContext, debug, initUi, themeService } from './utils';
import { AuthContext, useAuth } from './utils/hooks/useAuth';

const abilityContext = createAbilityContext();

const intl = createTinyIntl<string>({
  fallbackLocale: 'en-US',
  supportedLocales,
  loadDict: async (nextLoc) => {
    const dict = await load(nextLoc as I18nSupportedLocales);
    return dict.messages;
  },
  detectLocale: ({ supportedLocales, fallbackLocale }) => {
    return match(
      [navigator.language, ...navigator.languages],
      [...supportedLocales],
      fallbackLocale,
    );
  },
});

intl.subscribe(() => {
  const htmlEl = document.querySelector('html');
  if (htmlEl) {
    htmlEl.lang = intl.locale;
  }
});

function I18nLoader() {
  const { change, locale } = useIntl();

  const [i18nMounted, setI18nMounted] = useState(false);

  useMount(() => {
    performance.mark('startIntlMount');
    setI18nMounted(false);
    intl
      .mount()
      .then(() => {
        setI18nMounted(true);
        const measure = performance.measure('intlMount', 'startIntlMount');
        debug.log(`Intl mount in ${measure.duration}ms`);
      })
      .catch(() => {});
  });

  const { authenticatedUserId } = useAuth();
  const user = useSelector(() => {
    const userId = authenticatedUserId.get();
    if (!userId) return undefined;
    return store.getRow('users', userId).get();
  });

  useEffect(() => {
    if (i18nMounted && user) {
      const newLocale = user?.locale === 'de' ? 'de-DE' : 'en-US';

      if (locale !== newLocale) {
        change(newLocale);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, i18nMounted]);

  return <div className={!i18nMounted ? 'fixed inset-0 z-[49] bg-gray-1' : ''} />;
}

function ToasterContainer() {
  const theme = useSelector(() => {
    const darkTheme = themeService.darkTheme.get();
    return darkTheme ? 'dark' : 'light';
  });

  return <Toaster theme={theme} closeButton expand />;
}

export function App() {
  performance.mark('start-routing');

  themeService.mount();
  initUi();

  return (
    <>
      <AuthContext.Provider value={authenticator}>
        <TinyIntlContext.Provider value={intl}>
          <AbilityContext.Provider value={abilityContext}>
            <AppRouter />
            <I18nLoader />
          </AbilityContext.Provider>
        </TinyIntlContext.Provider>
      </AuthContext.Provider>
      <ToasterContainer />
    </>
  );
}
