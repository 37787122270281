import type { AbilityPermission } from '../hooks/useAbility';

import { createAliasResolver, createMongoAbility } from '../hooks/useAbility';

const resolveAction = createAliasResolver({
  read: ['get', 'find'],
  delete: 'remove',
});

export const createMongoAbilityFromPermissions = (perm: { resolved: AbilityPermission[] }[]) => {
  const resolvedArray = perm.reduce<AbilityPermission[]>((acc, v) => [...acc, ...v.resolved], []);
  return createMongoAbility(resolvedArray, {
    resolveAction,
  });
};
