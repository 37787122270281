import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { supportedCountries } from '@supertray/shared';
import { Trans, useIntl } from '@tiny-intl/react';
import { Form, Formik, useFormikContext } from 'formik';
import { toast } from 'sonner';

import { requests } from '../../api';
import { Button, FormikField, FormikSubmit, HtmlTrans, SelectField } from '../../components';
import { Page } from '../../features';
import { useFormikValidation, validator } from '../../utils';

function CountrySelect() {
  const { t, locale } = useIntl();
  const { values, setFieldValue } = useFormikContext<{ countryCode: string }>();

  useEffect(() => {
    setFieldValue('countryCode', locale.split('-')[0] === 'en' ? 'US' : 'DE');
  }, [locale, setFieldValue]);

  return (
    <SelectField
      required
      label={t('country')}
      value={values.countryCode}
      size="md"
      onChange={(e) => setFieldValue('countryCode', e.target.value)}
    >
      {supportedCountries.map((code) => (
        <SelectField.Option key={code} value={code}>
          {t(`countries.${code}`)}
        </SelectField.Option>
      ))}
    </SelectField>
  );
}

export function Signup() {
  const navigate = useNavigate();
  const { t, tc, locale } = useIntl();

  const { validate, attr } = useFormikValidation({
    firstName: [validator.required(t('validation.required'))],
    lastName: [validator.required(t('validation.required'))],
    email: [validator.required(t('validation.required')), validator.email(t('validation.email'))],
    password: [
      validator.required(t('validation.required')),
      validator.minLength(8, tc('validation.minLength', 8, { count: 8 })),
    ],

    countryCode: [validator.required(t('validation.required'))],
    terms: [validator.required(t('validation.required'))],
    privacy: [validator.required(t('validation.required'))],
  });

  const userLocale = locale.split('-')[0] as 'de' | 'en';

  return (
    <Page title={t('signup')}>
      <h1 className="mb-6 select-none text-2xl font-bold">
        FilesGPT — <Trans name="signup" />
      </h1>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          countryCode: userLocale === 'en' ? 'US' : 'DE',
          terms: 'off',
          privacy: 'off',
        }}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setErrors }) => {
          try {
            await requests.account.signup({
              ...values,
              locale: values.countryCode === 'DE' ? 'de' : 'en',
              terms: undefined,
              privacy: undefined,
            });
            window.location.replace(`/auth?signup=true&email=${values.email}`);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (err: any) {
            if (err.message === 'EmailAlreadyUsed') {
              setErrors({ email: t('validation.emailAlreadyUsed') });
            } else {
              toast.error(t('generalError'));
            }
          }
        }}
      >
        <Form className="flex flex-col gap-4">
          <FormikField
            {...attr.firstName}
            is="TextField"
            name="firstName"
            label={t('firstName')}
            size="md"
            autoComplete="given-name"
          />
          <FormikField
            {...attr.lastName}
            is="TextField"
            name="lastName"
            label={t('lastName')}
            size="md"
            autoComplete="family-name"
          />
          <FormikField
            {...attr.email}
            is="TextField"
            name="email"
            label={t('email')}
            size="md"
            autoComplete="username"
          />
          <FormikField
            {...attr.password}
            is="TextField"
            name="password"
            type="password"
            label={t('password')}
            autoComplete="current-password"
            size="md"
          />
          <CountrySelect />
          <FormikField
            {...attr.terms}
            is="CheckboxField"
            name="terms"
            label={
              <HtmlTrans
                name="readAndAcceptTermsAndConditions"
                options={{
                  link: `<a href="https://www.filesgpt.eu/terms/" target="_blank">${t(
                    'termsAndConditions',
                  )}</a>`,
                }}
              />
            }
            size="md"
          />
          <FormikField
            {...attr.privacy}
            is="CheckboxField"
            name="privacy"
            label={
              <HtmlTrans
                name="readAndAcceptPrivacyPolicy"
                options={{
                  link: `<a href="https://gridventures.de/datenschutzerklaerung/" target="_blank">${t(
                    'privacyPolicy',
                  )}</a>`,
                }}
              />
            }
            size="md"
          />
          <FormikSubmit variant="primary" size="md">
            <Trans name="signup" />
          </FormikSubmit>
          <div className="flex items-center">
            <hr className="flex-1" />
            <span className="px-4 text-gray-90">{t('haveAnAccount')}</span>
            <hr className="flex-1" />
          </div>
          <Button variant="secondary" size="md" onClick={() => navigate('../')}>
            <Trans name="signin" />
          </Button>
        </Form>
      </Formik>
    </Page>
  );
}
