import { NavLink } from 'react-router-dom';

import clsx from 'clsx';

import styles from './SubNavigation.module.css';

export type SubNavigationItemProps = {
  href: string;
  children: React.ReactNode;
};

export function SubNavigationItem(props: SubNavigationItemProps) {
  return (
    <li className="inline-flex">
      <NavLink
        to={props.href}
        className={({ isActive }) => {
          return clsx(styles.subNavigationItem, 'group py-1.5 text-base font-medium transition', {
            [styles.active]: isActive,
          });
        }}
      >
        <span className={clsx('inline-flex rounded-md py-0.5 px-2 leading-5', styles.inner)}>
          {props.children}
        </span>
      </NavLink>
    </li>
  );
}
