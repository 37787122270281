import type { IconNames } from '../Icon';

import { forwardRef, useMemo } from 'react';

import clsx from 'clsx';

import styles from './Button.module.css';
import { mergeProps } from '../../utils';
import { Icon } from '../Icon';

export type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  /**
   * @default 'sm'
   */
  size?: 'sm' | 'md';
  /**
   * @default 'secondary'
   */
  variant?: 'primary' | 'secondary' | 'tertiary';
  loading?: boolean;
} & (
  | {
      children: React.ReactNode;
      icon?: undefined;
    }
  | {
      children?: undefined;
      icon: IconNames;
    }
) &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'size' | 'type'>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    type,
    children,
    size,
    variant,
    loading,
    icon,
    className: classStr,
    disabled,
    ...restProps
  } = mergeProps(props, {
    size: 'sm',
    variant: 'secondary',
    type: 'button',
    disabled: false,
    loading: false,
  });

  const className = useMemo(() => {
    return clsx(styles.button, classStr, {
      [styles[size]]: size,
      [styles[variant]]: variant,
      [styles.loading]: loading,
      [styles.asIconButton]: !!icon,
    });
  }, [size, variant, loading, classStr, icon]);

  return (
    <button
      {...restProps}
      ref={ref}
      className={className}
      type={type}
      disabled={disabled || loading}
    >
      {icon && <Icon name={icon} size="20" />}
      {children}
      {loading && <span className={styles.loader} />}
    </button>
  );
});
Button.displayName = 'Button';
