/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
// import { Match, Switch, createMemo, For, splitProps } from 'solid-js';

import { useMemo } from 'react';

import clsx from 'clsx';

import icons from './icons';

export type IconNames = keyof typeof icons;

const SIZE_MAP = {
  '10': 10,
  '12': 12,
  '14': 14,
  '13': 13,
  '16': 16,
  '18': 18,
  '20': 20,
  '24': 24,
  '28': 28,
};

export type IconProps = {
  name: IconNames;
  label?: string;
  size?: keyof typeof SIZE_MAP;
  className?: string;
  title?: string;
} & React.SVGAttributes<SVGElement>;

export function Icon(props: IconProps) {
  const { name, label, title, className, size: sizeProp, ...rest } = props;

  const paths = useMemo(() => {
    return icons[name];
  }, [name]);
  const labeledBy = useMemo(() => {
    return (
      title ||
      label ||
      name
        .replace('hi-outline-', '')
        .replace('hi-mini-', '')
        .replace('bi-', '')
        .replace('-fill', '')
    );
  }, [name, label, title]);
  const heroIconOutline = useMemo(() => {
    return name.slice(0, 8) === 'hi-outl-';
  }, [name]);
  const heroIconMini = useMemo(() => {
    return name.slice(0, 8) === 'hi-mini-';
  }, [name]);
  const bootstrapIcon = useMemo(() => {
    return name.slice(0, 3) === 'bi-';
  }, [name]);
  const size = useMemo(() => {
    return sizeProp || (bootstrapIcon ? '16' : heroIconMini ? '20' : '24');
  }, [sizeProp, heroIconMini, bootstrapIcon]);
  const viewBox = useMemo(() => {
    if (heroIconMini) return '0 0 20 20';
    if (heroIconOutline) return '0 0 24 24';
    return '0 0 16 16';
  }, [heroIconMini, heroIconOutline]);
  const fill = useMemo(() => {
    if (heroIconOutline) return 'transparent';
    return 'currentColor';
  }, [heroIconOutline]);

  return (
    <svg
      {...rest}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      fill={fill}
      viewBox={viewBox}
      aria-label={labeledBy}
      className={clsx('inline-flex items-center justify-center', className)}
      fillRule={heroIconOutline ? undefined : 'evenodd'}
      strokeWidth={heroIconOutline ? 1.5 : undefined}
      stroke={heroIconOutline ? 'currentColor' : undefined}
      style={{
        width: `${Number(size) / 16}rem`,
        height: `${Number(size) / 16}rem`,
      }}
    >
      {(heroIconMini || bootstrapIcon) && paths.map((d, index) => <path d={d} key={index} />)}
      {heroIconOutline &&
        paths.map((d, index) => (
          <path key={index} strokeLinecap="round" strokeLinejoin="round" d={d} />
        ))}
    </svg>
  );
}
