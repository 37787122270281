import type { IconNames } from '../Icon';

import clsx from 'clsx';

import { mergeProps } from '../../utils';
import { Icon } from '../Icon';

export type AlertProps = {
  icon?: IconNames;
  className?: string;
  title?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
  variant?: 'primary' | 'error' | 'success' | 'secondary';
};

export function Alert(props: AlertProps) {
  const { icon, className, title, actions, children, variant } = mergeProps(props, {
    variant: 'secondary',
  });

  return (
    <div
      className={clsx('rounded-md p-4', className, {
        'bg-accent-30': variant === 'primary',
        'bg-red-30': variant === 'error',
        'bg-green-30': variant === 'success',
        'bg-gray-30': variant === 'secondary',
      })}
    >
      <div className="flex">
        {icon && (
          <div
            className={clsx('shrink-0', {
              'text-accent-110': variant === 'primary',
              'text-red-110': variant === 'error',
              'text-green-110': variant === 'success',
              'text-gray-110': variant === 'secondary',
            })}
          >
            <Icon name={icon} />
          </div>
        )}
        <div className="ml-3">
          {title && (
            <h3
              className={clsx('mb-2 text-sm font-medium', {
                'text-accent-120': variant === 'primary',
                'text-red-120': variant === 'error',
                'text-green-120': variant === 'success',
                'text-gray-120': variant === 'secondary',
              })}
            >
              {title}
            </h3>
          )}
          <div
            className={clsx('mt-0.5 text-sm', {
              'text-accent-110': variant === 'primary',
              'text-red-110': variant === 'error',
              'text-green-110': variant === 'success',
              'text-gray-110': variant === 'secondary',
            })}
          >
            <p>{children}</p>
          </div>
          {actions && (
            <div className="mt-3">
              <div className="flex">{actions}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
